import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import parse from "html-react-parser";

import { PageType } from "common/types/PageType/PageType";

import "./FooterModal.scss";

interface IFooterModal {
  setIsFooterContent: React.Dispatch<React.SetStateAction<boolean>>;
  footerPageInfo: PageType;
  setFooterPageInfo: React.Dispatch<React.SetStateAction<PageType | null>>;
}

const FooterModal = ({
  setIsFooterContent,
  footerPageInfo,
  setFooterPageInfo,
}: IFooterModal) => {
  const [searchParams] = useSearchParams();
  const helpPageContent = searchParams.get("helpPageContent") || "";
  const navigate = useNavigate();

  const handleCloseWindow = () => {
    navigate("", {
      replace: true,
    });
    setIsFooterContent(false);
    setFooterPageInfo(null);
  };
  return (
    <div className="popup-holder-bc windowed  content-manager ">
      <div className="popup-middleware-bc">
        <div className="popup-inner-bc">
          <i
            className="e-p-close-icon-bc bc-i-close-remove"
            onClick={handleCloseWindow}
          />
          <div className="help-popup">
            <div className="help-popup-title">
              <span className="help-popup-title-text ellipsis">
                {footerPageInfo.title}
              </span>
            </div>
            
              {parse(footerPageInfo.content)}
          </div>
        </div>
      </div>
    </div>
  );
};

export { FooterModal };
