import { t } from "i18next";
import React, { useEffect, useState } from "react";

import "./DepratmentInput.scss";
import { departmentList } from "common/types/AdminInfo/messages/departmentEnum";

interface IDepratmentInput {
 watch: any;
 register: any;
}

const DepratmentInput = ({ watch, register }: IDepratmentInput) => {
 const inputValue = watch("department_id");
 const [inputError, setInputError] = useState(false);
 const [inputOpen, setInputOpen] = useState(false);

 useEffect(() => {
  if (inputValue.length > 0) {
   setInputOpen(true);
  }
  setInputError(false);
 }, [inputValue]);
 return (
  <div className="u-i-p-control-item-holder-bc">
   <div
    className={`form-control-bc default ${inputOpen ? "focused" : ""} ${
     inputError ? "invalid" : ""
    }`}
   >
    <label className="form-control-label-bc inputs">
     <select
      id="department_id"
      className="form-control-input-bc"
      {...register("department_id", { required: true })}
     >
      {Object.entries(departmentList).map(([key, value]) => (
       <option key={value.id} value={value.id} className="form-control-input-bc-custom">
        {value.title}
       </option>
      ))}
     </select>
     <i className="form-control-input-stroke-bc" />
     <span className="form-control-title-bc ellipsis">
      {t("admin.Subject")}
     </span>
    </label>
    {inputError ? (
     <div className="form-control-message-holder-bc">
      <span className="form-control-message-bc">
       {t("admin.Thisfieldisrequired")}
      </span>
     </div>
    ) : null}
   </div>
  </div>
 );
};

export { DepratmentInput };
