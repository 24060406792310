import { t } from "i18next";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import "./MessagesAccount.scss";
import { MessagesNav } from "./navigation/MessagesNav";
import { InboxPage } from "./pages/InboxPage/InboxPage";
import { NewPage } from "./pages/NewPage/NewPage";
import { SentPage } from "./pages/SentPage/SentPage";
import { InboxChat } from "./pages/InboxChat/InboxChat";

const MessagesAccount = () => {
 const location = useLocation();
 const nanvigate = useNavigate();
 const [searchParams] = useSearchParams();
 const page = searchParams.get("page") || "";
 const handleGoBack = () => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.delete("account");
  searchParams.delete("page");
  nanvigate({
   search: searchParams.toString(),
  });
 };
 console.log(page === "inbox-chat");
 return (
  <div className="u-i-p-c-body-bc" style={{ maxWidth: "100%" }}>
   <div className="back-nav-bc" onClick={handleGoBack}>
    <i className="back-nav-icon-bc bc-i-round-arrow-left" />
    <span className="back-nav-title-bc ellipsis">{t("admin.Messages")}</span>
   </div>
   <MessagesNav page={page} />
   {page === "inbox" ? <InboxPage /> : null}
   {page === "sent" ? <SentPage /> : null}
   {page === "new" ? <NewPage /> : null}
   {page === "inbox-chat" ? <InboxChat /> : null}
  </div>
 );
};

export { MessagesAccount };
