import React, { useEffect, useState } from "react";
import { Layout } from "../../components/layout";

import "./Zeplin.scss";
import { useTypeSelector } from "hooks/useTypeSelector";
import { CasinoInfo } from "services";

const Zeplin = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const [gameLink, setGameLink] = useState("");
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const token = localStorage.getItem("token");
  useEffect(() => {
    async function searchPlaySecond() {
      try {
        if (token) {
          const res = await CasinoInfo.casinoPlay(
            `/spribe/aviator?currency=TRY&lang=${language}&platform=desktop&game_link=true`
          );
          setGameLink(res.data.data.game_link);
        } else {
          const res = await CasinoInfo.casinoPlayDemo(
            `/spribe/aviator?currency=TRY&lang=${language}&platform=desktop&game_link=true`
          );
          setGameLink(res.data.data.game_link);
        }
      } catch {
        console.error("");
      }
    }
    searchPlaySecond();
  }, []);
  return (
    <Layout
      containerStyles="is-home-page compact-footer hide-tab-navigation-footer-mobile "
      navHeader="zeplin"
      loadStop={false}
      footerNav="casino"
    >
      <div className="layout-content-holder-bc">
        <iframe
          className="iframe-full-page"
          title="https://pi.njoybingo.com/game.do?token=bea879be51aa8c2fd8f078ac6e5778fb_97b6132496b9c78d0bdd9d3d9a9f9dc2&pn=performads&game=PRPLAY-1301&type=FREE&lang=en"
          src="https://pi.njoybingo.com/game.do?token=bea879be51aa8c2fd8f078ac6e5778fb_97b6132496b9c78d0bdd9d3d9a9f9dc2&pn=performads&game=PRPLAY-1301&type=FREE&lang=en"
          allowFullScreen={true}
          allow="autoplay; microphone *"
        />
      </div>
    </Layout>
  );
};

export { Zeplin };
