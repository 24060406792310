import React, { useState } from "react";

import "./FullNameInput.scss";
import { t } from "i18next";

interface IFullNameInput {
  fullNameValue?: string | null;
  setFullNameValue?: React.Dispatch<React.SetStateAction<string | null>>;
}

const FullNameInput = ({ fullNameValue, setFullNameValue }: IFullNameInput) => {
  const [inputFocused, setInputFocused] = useState(false);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullNameValue && setFullNameValue(e.target.value);
  };

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    if (fullNameValue && fullNameValue.length === 0) {
      setInputFocused(false);
    } else {
      setInputFocused(true);
    }
  };
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div
        className={`form-control-bc default ${inputFocused ? "focused" : ""}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="text"
            className="form-control-input-bc"
            name="name"
            defaultValue=""
            value={fullNameValue || ""}
            onChange={onChangeInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.FullName")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { FullNameInput };
