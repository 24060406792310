import React, { useEffect, useState } from "react";

import "./FilterBetHistory.scss";
import load from "../../../../../../../accets/images/gif/loader/loader.gif";
import { t } from "i18next";
import { BetPeriod, BetType } from "../ui";
import {
  userBetsDataType,
  userBetsMetaType,
} from "common/types/AdminInfo/userBets";
import { useSearchParams } from "react-router-dom";
import { AdminPanel } from "services";

const FilterBetHistory = () => {
  const [selectOpen, setSelectOpen] = useState(false);
  const handleSelectMove = () => {
    setSelectOpen((prev) => !prev);
  };

  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "";

  const [betType, setBetType] = useState("");
  const [statusType, setStatusType] = useState("");

  const [period, setPeriod] = useState("24/1");
  const [dateRange, setDateRange] = useState("");

  const [loadBets, setLoadBets] = useState(false);
  const [data, setData] = useState<userBetsDataType[] | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<userBetsMetaType | null>(
    null
  );
  const [paginationPage, setPaginationPage] = useState(1);
  const handleShow = () => {
    async function getBets() {
      setLoadBets(true);
      try {
        const res = await AdminPanel.userBets(
          `?date=${dateRange}&type=${betType}&status=${statusType}&limit=10&page=${paginationPage}`
        );
        setData(res.data.data);
        setPaginationInfo(res.data.meta);
        setLoadBets(false);
      } catch {
        setLoadBets(false);
      }
    }
    getBets();
  };
  useEffect(() => {
    handleShow();
  }, [paginationPage]);

  const handleNext = () => {
    setPaginationPage((prevPage) =>
      Math.min(prevPage + 1, paginationInfo?.total || 10)
    );
  };

  const handlePrevious = () => {
    setPaginationPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  return (
    <div
      className="u-i-e-p-p-content-bc u-i-common-content"
      data-scroll-lock-scrollable=""
    >
      <div className="componentFilterWrapper-bc">
        <div
          className={`componentFilterLabel-bc ${selectOpen ? "active" : ""}`}
          onClick={handleSelectMove}
        >
          <i className="componentFilterLabel-filter-i-bc bc-i-filter" />
          <div className="componentFilterLabel-filter-bc">
            <p className="ellipsis">{t("admin.Filter")}</p>
          </div>
          <i className="componentFilterChevron-bc bc-i-small-arrow-down" />
        </div>
        <div className="componentFilterBody-bc">
          <div className="componentFilterElsWrapper-bc">
            <div className="filter-form-w-bc">
              <BetType
                betType={betType}
                setBetType={setBetType}
                setPaginationPage={setPaginationPage}
              />
              <BetPeriod />
              <div className="u-i-p-c-footer-bc">
                <button
                  className="btn a-color "
                  type="submit"
                  title="Show"
                  onClick={handleShow}
                >
                  <span>{t("admin.Show")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadBets ? (
        <div className="bc-loader medium ">
          <div className="bc-loader-contain">
            <img src={load} alt="" />
          </div>
        </div>
      ) : (
        <>
          {data ? (
            <div className="table-container">
              <table className="custom-table">
                <thead className="table-header">
                  <tr>
                    <th className="header-cell">Game</th>
                    <th className="header-cell">Round ID</th>
                    <th className="header-cell">Amount Of Bet</th>
                    <th className="header-cell">Win Amount</th>
                    <th className="header-cell">Currency</th>
                    <th className="header-cell">Status</th>
                    <th className="header-cell">Date</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {data.map((item) => (
                    <tr className="table-row" key={item.id}>
                      <td className="table-cell">{item.game_name}</td>
                      <td className="table-cell">{item.id}</td>
                      <td className="table-cell">
                        {item.bet_amount.toFixed(2)}
                      </td>
                      <td className="table-cell">
                        {item.win_amount.toFixed(2)}
                      </td>
                      <td className="table-cell">{item.currency}</td>
                      <td className="table-cell">{item.status}</td>
                      <td className="table-cell">
                        {new Date(item.date).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <button className="pagination-button" onClick={handlePrevious}>
                  &lt;
                </button>
                <span className="pagination-info">
                  {paginationPage} of {paginationInfo?.total}
                </span>
                <button className="pagination-button" onClick={handleNext}>
                  &gt;
                </button>
              </div>
            </div>
          ) : (
            <p className="empty-b-text-v-bc">{t("admin.Nobetstoshow")}</p>
          )}
        </>
      )}
    </div>
  );
};

export { FilterBetHistory };
