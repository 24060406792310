import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import "./Carusel.scss";
import "swiper/css";
import { CaruselCart } from "./caruselCart/сaruselCart";
import {
  BananersEnum,
  BannersList,
} from "../../common/types/Banners/BannersList";
import { useNavigate } from "react-router-dom";
import { useTypeSelector } from "srcm/hooks/useTypeSelector";

type SwiperInstance = SwiperType | null;

interface ICarusel {
  mockCarusel: BannersList[];
  filtredSelect: BananersEnum;
}

const Carusel = ({ mockCarusel, filtredSelect }: ICarusel) => {
  const activeIndexRef = useRef(1);
  const [activeIndexShow, setActiveIndexShow] = useState(1);
  const [swiperInstance, setSwiperInstance] = useState<SwiperInstance>(null);
  const lang = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const navigate = useNavigate();

  const bannersList = mockCarusel.filter((item) => item.type === filtredSelect);

  const goToNextSlide = () => {
    if (swiperInstance) {
      const { activeIndex, slides } = swiperInstance;
      if (activeIndex === slides.length - 1) {
        swiperInstance.slideToLoop(0);
        activeIndexRef.current = 1;
      } else {
        swiperInstance.slideNext();
        activeIndexRef.current = activeIndex + 1;
      }
      setActiveIndexShow(activeIndexRef.current);
    }
  };

  const goToPrevSlide = () => {
    if (swiperInstance) {
      const { activeIndex, slides } = swiperInstance;
      if (activeIndex === 0) {
        swiperInstance.slideToLoop(slides.length - 1);
        activeIndexRef.current = slides.length;
      } else {
        swiperInstance.slidePrev();
        activeIndexRef.current = activeIndex;
      }
      setActiveIndexShow(activeIndexRef.current); 
    }
  };

  const handleSwiperInstance = (swiper: any) => {
    setSwiperInstance(swiper);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide();
    }, 5000); 

    return () => clearInterval(interval); 
  }, [swiperInstance]);

  const handleNavigate = (item: BannersList) => {
    if (item.url) {
      navigate(`/${lang}/${item.url}`);
    }
  };

  return (
    <>
      {bannersList.length > 0 ? (
        <div
          className={`hm-row-bc has-slider custom-slider-container`}
          style={{ gridTemplateColumns: "12fr" }}
        >
          <div className="slider-bc">
            <div className="carousel">
              <div className="carousel-count-arrow-container with-count custom-counter">
                <i
                  className="carousel-arrow-left bc-i-small-arrow-left"
                  onClick={goToPrevSlide}
                ></i>
                <div className="carousel-count-arrow">
                  {activeIndexShow} / {bannersList.length}
                </div>
                <i
                  className="carousel-arrow-right bc-i-small-arrow-right"
                  onClick={goToNextSlide}
                ></i>
              </div>
              <div className="carousel-viewport">
                <Swiper
                  className="carousel-container"
                  loop={true}
                  spaceBetween={50}
                  slidesPerView={1}
                  onSwiper={handleSwiperInstance}
                >
                  {bannersList.map((item) => {
                    return (
                      <SwiperSlide key={item.mobile_image.s3}>
                        <CaruselCart
                          cartCaruselInfo={item}
                          key={item.id}
                          handleNavigate={handleNavigate}
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { Carusel };
