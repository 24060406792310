import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "../../../../../../../hooks/useTypeSelector";

import "./MainItems.scss";

interface IMainItems {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MainItems = ({ setSidebarOpen }: IMainItems) => {
  const handleOpenVirtualSport = () => {
    setSidebarOpen(true);
  };
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  return (
    <div className="m-block-nav-items-bc" data-scroll-lock-scrollable="">
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/promotions/all`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-promotion" />
            </div>
            <p className="m-nav-list-item-title-bc">
              {t("navHeader.Promotions")}
            </p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/casino/game-view/806666`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-default-icon" />
            </div>
            <p className="m-nav-list-item-title-bc">{t("navHeader.AVIATOR")}</p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/casino/tournaments`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-tournament" />
            </div>
            <p className="m-nav-list-item-title-bc">
              {t("navHeader.Tournaments")}
            </p>
          </div>
        </Link>
      </div>
      {/* <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/wonderwheel`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-wonderwheel" />
            </div>
            <p className="m-nav-list-item-title-bc">
              {t("navHeader.MALTBAHISCARK")}
            </p>
          </div>
        </Link>
      </div> */}
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/casino/game-view/400043028`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-default-icon" />
            </div>
            <p className="m-nav-list-item-title-bc">
              {t("navHeader.SPACEMAN")}
            </p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/casino/game-view/9010`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-zeppelin" />
            </div>
            <p className="m-nav-list-item-title-bc">{t("navHeader.ZEPLIN")}</p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/sports/live/event-view`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-live" />
            </div>
            <p className="m-nav-list-item-title-bc">{t("navHeader.Live")}</p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/sports/pre-match/event-view`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-prematch" />
            </div>
            <p className="m-nav-list-item-title-bc">{t("navHeader.Sport")}</p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/casino/slots/-1`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-casino" />
            </div>
            <p className="m-nav-list-item-title-bc">{t("navHeader.Casino")}</p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/live-casino/home/-1/All`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-livecasino" />
            </div>
            <p className="m-nav-list-item-title-bc">
              {t("navHeader.LiveCasino")}
            </p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          className="app-nav-link"
          target="_self"
          to={`/${language}/casino/jackpot`}
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-jackpots" />
            </div>
            <p className="m-nav-list-item-title-bc">{t("navHeader.Jackpot")}</p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link className="app-nav-link" target="_self" to={`/${language}/games`}>
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-games" />
            </div>
            <p className="m-nav-list-item-title-bc">{t("navHeader.Games")}</p>
          </div>
        </Link>
      </div>
      <div className="app-nav-arrow-block-bc" onClick={handleOpenVirtualSport}>
        <div className="app-nav-link">
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-virtualbetting" />
            </div>
            <p className="m-nav-list-item-title-bc">
              {t("navHeader.VirtualSports")}
            </p>
          </div>
        </div>
        <i className="m-nav-arrow-icon-bc bc-i-small-arrow-right custom-virtual-arrow" />
      </div>
      <div className="app-nav-arrow-block-bc">
        <Link
          to="https://statistics.bcapps.org/#/en"
          target="_self"
          className="app-nav-link"
        >
          <div className="m-nav-items-list-item-bc ">
            <div className="nav-ico-w-row-bc">
              <i className="m-nav-icon-bc bc-i-statistics" />
            </div>
            <p className="m-nav-list-item-title-bc">
              {t("navHeader.Statistics")}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export { MainItems };
