import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { userDeposit } from "../../../../../../../../common/types/AdminInfo/userDeposit";
import { AdminPanel } from "../../../../../../../../services";

import "./WithdrawPayForm.scss";
import { SuccesModal } from "../../../../../../../../components/modal";
import { WitdhrowExtraData } from "./WitdhrowExtraData/WitdhrowExtraData";
import { getPayment } from "./util/getPayment";
import { useTypeSelector } from "../../../../../../../../hooks/useTypeSelector";

interface IWithdrawPayForm {
  selectedPaymentMethod: userDeposit | undefined;
}

const WithdrawPayForm = ({ selectedPaymentMethod }: IWithdrawPayForm) => {
  const [modalSuccess, setModalSuccess] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [inputOpen, setInputOpen] = useState(false);

  const [inputValue, setInputValue] = useState("");
  const [bankCodeValue, setBankCodeValue] = useState<string | null>(null);
  const [fullNameValue, setFullNameValue] = useState<string | null>(null);
  const [ibanValue, setIbanValue] = useState<string | null>(null);
  const [lastCharsValue, setLastCharsValue] = useState<string | null>(null);
  const [idValue, setIdValue] = useState<string | null>(null);
  const [addNotesValue, setAddNotesValue] = useState<string | null>(null);
  const [accountNumberValue, setAccountNumberValue] = useState<string | null>(
    null
  );
  const [descriptionValue, setDescriptionValue] = useState<string | null>(null);
  const [walletIdValue, setWalletIdValue] = useState<string | null>(null);

  const id = useTypeSelector(
    (data) => data.accountInfoReducer.user.active_bonus?.id
  );

  const location = useLocation();
  const nanvigate = useNavigate();
  const { active_bonus } = useTypeSelector(
    (data) => data.accountInfoReducer.user
  );

  const handleAddInputValue = (value: number) => {
    setInputValue((prev) => String(Number(prev) + value));
    setInputOpen(true);
    setInputError(false);
  };
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Оставить только цифры
    if (
      Number(numericValue) >
      Number(selectedPaymentMethod?.currencies[0]?.min) - 1
    ) {
      setInputError(false);
    } else {
      setInputError(true);
    }
    setInputValue(numericValue);
  };
  const handleFocus = () => {
    setInputOpen(true);
  };
  const handleBlur = () => {
    if (inputValue.length > 0) {
      setInputOpen(true);
      setInputError(false);
    } else {
      setInputOpen(false);
      setInputError(true);
    }
  };
  useEffect(() => {
    if (
      Number(inputValue) * 100 >=
      Number(selectedPaymentMethod?.currencies[0]?.min)
    ) {
      setInputError(false);
    } else {
      setInputError(true);
    }
  }, [inputValue]);
  const handleGoBack = () => {
    nanvigate(-1);
  };
  const handleMoveToPay = () => {
    if (active_bonus) {
      setModalSuccess(true);
    } else {
      getPayment({
        inputValue: inputValue,
        extra_data: {
          accountNumber: accountNumberValue,
          bankCode: bankCodeValue,
          description: descriptionValue,
          iban: ibanValue,
          id: idValue,
          nameSurname: fullNameValue,
          notes: addNotesValue,
          tcNum: "2312",
          wallet_id: walletIdValue,
        },
        service_id: Number(selectedPaymentMethod?.service_id),
      });
    }
  };
  const handlePayAfterBonus = async () => {
    try {
      await AdminPanel.deleteBonus({ data: id || 1 });
    } catch (e) {
      alert('bonuse deleted')
      alert(e);
    } finally {
      await getPayment({
        inputValue: inputValue,
        extra_data: {
          accountNumber: accountNumberValue,
          bankCode: bankCodeValue,
          description: descriptionValue,
          iban: ibanValue,
          id: idValue,
          nameSurname: fullNameValue,
          notes: addNotesValue,
          tcNum: "2312",
          wallet_id: walletIdValue,
        },
        service_id: Number(selectedPaymentMethod?.service_id),
      });
      setModalSuccess(false);
      setInputValue("");
      setInputError(true);
    }
  };
  return (
    <>
      <div
        className="modal-overlay"
        style={{ display: modalSuccess ? "flex" : "none" }}
        // onClick={onClose}
      >
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <p>Do you want to delete the active bonus?</p>
          <button onClick={handlePayAfterBonus}>Yes</button>
          <button onClick={() => setModalSuccess(false)}>No</button>
        </div>
      </div>
      <div
        className="overlay-sliding-wrapper-bc  "
        style={{ transform: "translateY(0px)", opacity: 1 }}
      >
        {modalSuccess ? (
          <SuccesModal
            modalTest="Request successful"
            setModalSuccess={setModalSuccess}
          />
        ) : null}
        <div
          className="overlay-sliding-w-c-content-slider-bc"
          style={{ display: "flex", flexDirection: "column" }}
          data-scroll-lock-scrollable=""
        >
          <div className="back-nav-bc" onClick={handleGoBack}>
            <i className="back-nav-icon-bc bc-i-round-arrow-left" />
            <span className="back-nav-title-bc ellipsis">
              {selectedPaymentMethod
                ? selectedPaymentMethod.payment_name ||
                  selectedPaymentMethod.name
                : ""}
            </span>
          </div>
          <div className="payment-info-bc" data-scroll-lock-scrollable="">
            <div className="description-c-row-bc HedefHavale">
              <div className="description-c-row-column-bc pay-logo">
                <img
                  // src="https://cmsbetconstruct.com/content/images/payments/custom/1873892/10795.png"
                  alt=""
                />
              </div>
              <div className="description-c-row-column-bc texts">
                <div className="description-c-row-c-title-bc has-border">
                  <div className="description-c-r-c-t-column-bc">
                    <span className="description-title ellipsis">
                      {t("admin.Fee")}: Free
                    </span>
                  </div>
                  <div className="description-c-r-c-t-column-bc">
                    <span>&nbsp;</span>
                  </div>
                </div>
                <div className="description-c-row-c-title-bc ">
                  <div className="description-c-r-c-t-column-bc">
                    <span className="description-title  ellipsis" title="Min">
                      {t("admin.Min")}
                    </span>
                    <span className="description-value">
                      {selectedPaymentMethod
                        ? selectedPaymentMethod.currencies[0].min
                        : ""}{" "}
                      ₺
                    </span>
                  </div>
                  <div className="description-c-r-c-t-column-bc">
                    <span className="description-title  ellipsis" title="Max">
                      {t("admin.Max")}
                    </span>
                    <span className="description-value">
                      {selectedPaymentMethod
                        ? selectedPaymentMethod.currencies[0].max
                        : ""}{" "}
                      ₺
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="pay-button-container">
              <div className="pay-button-form">
                <div
                  className="pay-button-text"
                  onClick={() =>
                    handleAddInputValue(
                      Number(selectedPaymentMethod?.currencies[0]?.min)
                    )
                  }
                >
                  {Number(selectedPaymentMethod?.currencies[0]?.min)}
                </div>
              </div>
              <div className="pay-button-form">
                <div
                  className="pay-button-text"
                  onClick={() =>
                    handleAddInputValue(
                      Number(selectedPaymentMethod?.currencies[0]?.min) * 5
                    )
                  }
                >
                  {Number(selectedPaymentMethod?.currencies[0]?.min) * 5}
                </div>
              </div>
              <div className="pay-button-form">
                <div
                  className="pay-button-text"
                  onClick={() =>
                    handleAddInputValue(
                      Number(selectedPaymentMethod?.currencies[0]?.min) * 10
                    )
                  }
                >
                  {Number(selectedPaymentMethod?.currencies[0]?.min) * 10}
                </div>
              </div>
            </div>
            {/* <div className="WithdrawAmount-All">
              <ul className="WithdrawAmount-Row">
                <li className="WithdrawAmount-Row-Title">
                  {t("admin.WithdrawableAmount")}
                </li>
                <li className="WithdrawAmount-Row-Value">0.00 ₺</li>
              </ul>
              <ul className="WithdrawAmount-Row">
                <li className="WithdrawAmount-Row-Title">
                  {t("admin.Balance")}
                </li>
                <li className="WithdrawAmount-Row-ValueBalance">0.00 ₺</li>
              </ul>
              <ul className="WithdrawAmount-Row">
                <li className="WithdrawAmount-Row-Title">
                  {t("admin.Unplayedamount")}
                </li>
                <li className="WithdrawAmount-Row-Value">0.00 ₺</li>
              </ul>
            </div> */}

            <div className="deposit-method-description HedefHavale">
              {selectedPaymentMethod
                ? selectedPaymentMethod.payment_name ||
                  selectedPaymentMethod.name
                : ""}
            </div>
            <div className="withdraw-form-l-bc">
              <>
                <WitdhrowExtraData
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  bankCodeValue={bankCodeValue}
                  setBankCodeValue={setBankCodeValue}
                  fullNameValue={fullNameValue}
                  setFullNameValue={setFullNameValue}
                  ibanValue={ibanValue}
                  setIbanValue={setIbanValue}
                  lastCharsValue={lastCharsValue}
                  setLastCharsValue={setLastCharsValue}
                  idValue={idValue}
                  setIdValue={setIdValue}
                  addNotesValue={addNotesValue}
                  setAddNotesValue={setAddNotesValue}
                  accountNumberValue={accountNumberValue}
                  setAccountNumberValue={setAccountNumberValue}
                  descriptionValue={descriptionValue}
                  setDescriptionValue={setDescriptionValue}
                  walletIdValue={walletIdValue}
                  setWalletIdValue={setWalletIdValue}
                  payment_name={selectedPaymentMethod?.payment_name}
                  payment_aggregator_id={
                    selectedPaymentMethod?.payment_aggregator_id
                  }
                  id={selectedPaymentMethod?.id}
                />
                {/* <div className="u-i-p-control-item-holder-bc">
                <div
                  className={`form-control-bc default ${
                    inputOpen ? "focused" : ""
                  } ${inputError ? "invalid" : "valid"}`}
                >
                  <label className="form-control-label-bc inputs">
                    <input
                      type="text"
                      inputMode="decimal"
                      className="form-control-input-bc"
                      name="amount"
                      value={inputValue}
                      onChange={handleChangeInput}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                    <i className="form-control-input-stroke-bc" />
                    <span className="form-control-title-bc ellipsis">
                      {t("admin.Amount")}
                    </span>
                  </label>
                  {inputError ? (
                    <div className="form-control-message-holder-bc">
                      <span className="form-control-message-bc">
                        {t("admin.Thisfieldisrequired")}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div> */}
                <div className="u-i-p-c-footer-bc">
                  <button
                    className="btn a-color deposit "
                    type="submit"
                    title="DEPOSIT"
                    disabled={inputError}
                    onClick={handleMoveToPay}
                  >
                    <span>{t("admin.doWithdraw")}</span>
                  </button>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { WithdrawPayForm };
