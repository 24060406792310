import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContentType } from "../../common/types/Content/ContentType";

type Ilanguage = {
  languageShort: string;
  languageAbs: string;
  languageFlag: string;
  languageName: string;
};

interface Language {
  languageShort: string;
  languageAbs: string;
  languageFlag: string;
  languageName: string;
}

interface SettingsState {
  oddsFormat: string;
  language: Language;
  timeFormat: string;
  showRedirectInfo: boolean;
  content: ContentType;
}

const initialState: SettingsState = {
  oddsFormat: "Decimal",
  language: {
    languageShort: "tr",
    languageAbs: "TRY",
    languageFlag: "turkey",
    languageName: "Türkçe",
  },
  timeFormat: "24h",
  showRedirectInfo: false,
  content: {
    mobileFooter: [],
    desktopFooter: {
      id: 0,
      position: "",
      content: "",
    },
    header: {
      id: 0,
      settings: null,
      items: [],
    },
    mobileHeader: {
      id: 0,
      settings: null,
      items: [],
    },
    quickNav: {
      id: 0,
      items: [],
    },
    mobileMenu: [],
    mobileMenuItems: [],
    skinStyles: [],
    favicons: [],
    scripts: []
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setOddsFormat: (state, action: PayloadAction<string>) => {
      state.oddsFormat = action.payload;
    },
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    setTimeFormat: (state, action: PayloadAction<string>) => {
      state.timeFormat = action.payload;
    },
    setRedirectInfo: (state, action: PayloadAction<boolean>) => {
      state.showRedirectInfo = action.payload;
    },
    setContent: (state, action: PayloadAction<ContentType>) => {
      state.content = action.payload;
    },
  },
});

export const {
  setOddsFormat,
  setLanguage,
  setTimeFormat,
  setRedirectInfo,
  setContent,
} = settingsSlice.actions;
export default settingsSlice;

export type { Language };

export type { Ilanguage };
