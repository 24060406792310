import { t } from "i18next";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ProfileNav } from "./navigation/ProfileNav";
import { ChangePasswordPage } from "./pages/ChangePasswordPage/ChangePasswordPage";
import { PersonalDetailsPage } from "./pages/PersonalDetailsPage/PersonalDetailsPage";
import { TwoFactorAuthentication } from "./pages/TwoFactorAuthentication/TwoFactorAuthentication";
import { VerifyAccountPage } from "./pages/VerifyAccountPage/VerifyAccountPage";

import "./ProfileAccout.scss";

const ProfileAccout = () => {
  const location = useLocation();
  const nanvigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "";
  const handleGoBack = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("account");
    searchParams.delete("page");
    nanvigate({
      search: searchParams.toString(),
    });
  };
  return (
    <div className="u-i-p-c-body-bc" style={{ maxWidth: "100%" }}>
      <div className="back-nav-bc" onClick={handleGoBack}>
        <i className="back-nav-icon-bc bc-i-round-arrow-left" />
        <span className="back-nav-title-bc ellipsis">{t("admin.MyProfile")}</span>
      </div>
      <ProfileNav page={page} />
      {page === "details" ? <PersonalDetailsPage /> : null}
      {page === "change-password" ? <ChangePasswordPage /> : null}
      {page === "verify-account" ? <VerifyAccountPage /> : null}
      {page === "two-factor-authentication" ? <TwoFactorAuthentication /> : null}
    </div>
  );
};

export { ProfileAccout };
