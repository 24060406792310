import React from "react";

import "./FooterBetzula.scss";
import { BananersEnum } from "common/types/Banners/BannersList";
import { useTypeSelector } from "hooks/useTypeSelector";
import { Link } from "react-router-dom";

const FooterBetzula = () => {
 const mockCarusel = useTypeSelector((data) => data.accountInfoReducer.banners);
 const bannerList = mockCarusel?.filter(
  (item) => item.type === BananersEnum.HomepageSingle
 );
 return (
  <div className="footerSliderSingleElWrapper">
   <div className="footerSliderSingleEl">
    <Link to="#" target="_blank" className="footerSliderImage" rel="noreferrer">
     <img
      loading="lazy"
      src={bannerList ? bannerList[0]?.desktop_image?.s3 : ""}
      alt=""
     />
    </Link>
    <Link to="#" target="_blank" className="footerSliderImage" rel="noreferrer">
     <img
      loading="lazy"
      src={bannerList ? bannerList[0]?.desktop_image?.s3 : ""}
      alt=""
     />
    </Link>
   </div>
  </div>
 );
};

export { FooterBetzula };
