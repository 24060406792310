import React from "react";
import { Layout } from "../../components/layout";

import "./LiveCalendar.scss";

const LiveCalendar = () => {
  return (
    <Layout
      containerStyles="is-home-page  "
      navHeader="liveCalendar"
      footerNav="sports"
    >
      <div className="layout-content-holder-bc">
        <div className="live-calendar">
          <p className="empty-b-text-v-bc">No games available</p>
        </div>
      </div>
    </Layout>
  );
};

export { LiveCalendar };
