import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./BalanceAccountNav.scss";

interface IBalanceAccountNav {
  page: string;
}

const BalanceAccountNav = ({ page }: IBalanceAccountNav) => {
  return (
    <div className="hdr-navigation-scrollable-bc">
      <div
        className="hdr-navigation-scrollable-content"
        data-scroll-lock-scrollable=""
      >
        <Link
          className={`hdr-navigation-link-bc ${
            page === "deposit" ? "active" : ""
          }`}
          to={{
            search:
              "profile=open&account=balance&page=deposit&selectedGroup=all",
          }}
        >
          {t("admin.Deposit")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "withdraw" ? "active" : ""
          }`}
          to={{
            search:
              "profile=open&account=balance&page=withdraw&selectedGroup=all",
          }}
        >
          {t("admin.Withdrawal")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "history" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=balance&page=history",
          }}
        >
          {t("admin.TransactionHistory")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
        <Link
          className={`hdr-navigation-link-bc ${
            page === "withdraws" ? "active" : ""
          }`}
          to={{
            search: "profile=open&account=balance&page=withdraws",
          }}
        >
          {t("admin.WithdrawStatus")}
          <i className="  count-blink-even " data-badge="" />
        </Link>
      </div>
    </div>
  );
};

export { BalanceAccountNav };
