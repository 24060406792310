import React, { useEffect, useState } from "react";
import { Layout } from "../../components/layout";

import "./Aviator.scss";
import { useTypeSelector } from "hooks/useTypeSelector";
import { CasinoInfo } from "services";

const Aviator = () => {
  const [panelOnep, setPanelOpen] = useState(true);
  const [betsLipOpen, setBetsLipOpen] = useState(false);
  const [gameLink, setGameLink] = useState("");
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const token = localStorage.getItem("token");
  useEffect(() => {
    async function searchPlaySecond() {
      try {
        if (token) {
          const res = await CasinoInfo.casinoPlay(
            `/spribe/aviator?currency=TRY&lang=${language}&platform=desktop&game_link=true`
          );
          setGameLink(res.data.data.game_link);
        } else {
          const res = await CasinoInfo.casinoPlayDemo(
            `/spribe/aviator?currency=TRY&lang=${language}&platform=desktop&game_link=true`
          );
          setGameLink(res.data.data.game_link);
        }
      } catch {
        console.error("");
      }
    }
    searchPlaySecond();
  }, []);
  return (
    <Layout
      containerStyles="is-home-page compact-footer hide-tab-navigation-footer-mobile "
      navHeader="aviator"
      loadStop={false}
      footerNav="casino"
    >
      <div className="layout-content-holder-bc">
        <iframe
          className="iframe-full-page"
          title={gameLink}
          src={gameLink}
          allowFullScreen={true}
          allow="autoplay; microphone *"
        />
      </div>
    </Layout>
  );
};

export { Aviator };
