import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./InboxPage.scss";
import { messagesListResponce } from "common/types/AdminInfo/messages/messagesListResponce";
import { AdminPanel } from "services";

const InboxPage = () => {
 const [loading, setLoading] = useState(true);
 const [messages, setMessages] = useState<messagesListResponce[]>([]);
 useEffect(() => {
  setTimeout(() => {
   setLoading(false);
  }, 2000);
 });
 const getUsers = () => {
  AdminPanel.ticketList("?page=1&type=inbox")
   .then((res) => {
    setLoading(true);
    setMessages(res.data);
   })
   .catch((e) => console.error(e))
   .finally(() => {
    setLoading(false);
   });
 };
 useEffect(() => {
  getUsers();
 }, []);

 return (
  <div className="messageBoxWrapper-bc">
   {loading ? (
    <ul className="messageBoxList-bc">
     <div className="message-block-bc" data-status="read">
      <div className="message-title-bc skeleton-loader-text" />
      <div className="message-more-time-bc">
       <span className="skeleton-loader-full-text" />
      </div>
     </div>
     <div className="message-block-bc" data-status="read">
      <div className="message-title-bc skeleton-loader-text" />
      <div className="message-more-time-bc">
       <span className="skeleton-loader-full-text" />
      </div>
     </div>
    </ul>
   ) : (
    <>
     <p className="empty-b-text-v-bc">{t("admin.Youdonothaveanymessages")}</p>
     <Link
      className="btn a-color"
      to={{
       search: "profile=open&account=messages&page=new",
      }}
     >
      {t("admin.Newmessage")}
     </Link>
    </>
   )}
  </div>
 );
};

export { InboxPage };
