import { t } from "i18next";
import React, { useState } from "react";
import { Layout } from "../../components/layout";
import { TournamentProloader } from "../../components/tournaments/TournamentProloader/TournamentProloader";
import { TournamentsFilter } from "../../components/tournaments/TournamentsFilter/TournamentsFilter";

import "./Tournaments.scss";

const Tournaments = () => {
  const [loading, setLoading] = useState(false);
  return (
    <Layout
      containerStyles="is-home-page casino-slots compact-footer "
      navHeader="tournaments"
      footerNav=""
    >
      <div className="layout-content-holder-bc">
        <TournamentsFilter setLoading={setLoading} />
        {loading ? (
          <TournamentProloader />
        ) : (
          <p className="empty-b-text-v-bc">
            {t("admin.Therearenotournamentswithselectedfilters")}.
          </p>
        )}
      </div>
    </Layout>
  );
};

export { Tournaments };
