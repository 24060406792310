const convertDateTime = (dateString: string) => {
 const date = new Date(dateString);

 const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
 ];

 const day = date.getDate();
 const month = months[date.getMonth()];
 const hours = String(date.getHours()).padStart(2, "0");
 const minutes = String(date.getMinutes()).padStart(2, "0");

 return `${day} ${month} ${hours}:${minutes}`;
};

export { convertDateTime };
