import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./i18n/locale/en/enTranslation.json";
import trTranslation from "./i18n/locale/ru/trTranslation.json";
import deTranslation from "./i18n/locale/de/deTranslation.json";

import enTranslationMobile from "./srcm/i18n/locale/en/enTranslation.json";
import trTranslationMobile from "./srcm/i18n/locale/ru/trTranslation.json";

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "tr",
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      tr: {
        translation: isMobile ? trTranslationMobile : trTranslation,
      },
      en: {
        translation: isMobile ? enTranslationMobile : enTranslation,
      },
      de: {
        translation: deTranslation,
      },
    },
  });

export default i18n;
