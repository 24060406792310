import { t } from "i18next";
import React, { useEffect, useState } from "react";

import "./MessageInput.scss";

interface IMessageInput {
  watch: any;
  register: any;
 }

const MessageInput = ({ watch, register }: IMessageInput) => {
  const inputValue = watch("message");
 const [inputError, setInputError] = useState(false);
 const [inputOpen, setInputOpen] = useState(false);
 const handleFocus = () => {
  setInputOpen(true);
 };
 const handleBlur = () => {
  if (inputValue.length > 0) {
   setInputOpen(true);
  } else {
   setInputOpen(false);
   setInputError(true);
  }
 };
 useEffect(() => {
  setInputError(false);
  if (inputValue.length > 0) {
   setInputOpen(true);
  }
 }, [inputValue]);
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div
        className={`form-control-bc default ${inputOpen ? "focused" : ""} ${
          inputError ? "invalid" : ""
        }`}
      >
        <label className="form-control-label-bc textareas">
          <div className="form-control-textarea-bc">
            <textarea
              placeholder="Enter text here"
              className="form-control-textarea"
              name="body"
              maxLength={4000}
              {...register("message", { required: true })}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Messages")}
          </span>
        </label>
        {inputError ? (
          <div className="form-control-message-holder-bc">
            <span className="form-control-message-bc">
              {t("admin.Thisfieldisrequired")}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { MessageInput };
