import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CasinoIconGameType } from "../../../common/types/Casino/CasinoIconGameType";
import { CasinoInfo } from "../../../services";
import { CasinoCart } from "../casinoCart/CasinoCart";

import "./LiveCasinoSlot.scss";
import { useTypeSelector } from "hooks/useTypeSelector";

interface ILiveCasinoSlot {
 casinoGames: CasinoIconGameType[];
}

const LiveCasinoSlot = ({ casinoGames }: ILiveCasinoSlot) => {
 const [checkedGame, setCheckedGame] = useState(-1);
 const languageShort = useTypeSelector((data) => data.settingsReducer.language);
 const allGames =
  useTypeSelector(
   (data) =>
    data?.settingsReducer?.content?.skinStyles?.filter(
     (item: any) => item.key === "home-page-list"
    )[0]?.value
  ) || null;
 const uniqueArray =
  casinoGames?.filter(
   (obj: any, index: any, self: any) =>
    index === self?.findIndex((t: any) => t.launch_code === obj.launch_code)
  ) || null;

 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
 // @ts-ignore
 const chekIdList = JSON.parse(allGames)?.row_2 || "";
 const filteredObjects =
  uniqueArray?.filter((obj: any) =>
   chekIdList?.includes(obj.launch_code.toString())
  ) || null;

 return (
  <div className="hm-row-bc " style={{ gridTemplateColumns: "12fr" }}>
   <div className="page-view-more">
    <h3 className="view-title-more ellipsis custom-font-size">
     {t("navHeader.LiveCasino")}
    </h3>
    <Link
     className="view-see-more"
     to={`/${languageShort}/live-casino/home/-1/All`}
    >
     {t("casino.More")}
     <i className="view-arrow-more bc-i-small-arrow-right" />
    </Link>
   </div>
   <div className="games-horiz casino-game-item-amount">
    <div
     className="games-horiz-scroll games-horiz-scroll-custom"
     data-scroll-lock-scrollable=""
     style={{ gridTemplateRows: "auto" }}
    >
     {filteredObjects?.map((item) => {
      return (
       <CasinoCart
        cartInfo={item}
        key={item.id}
        checkedGame={checkedGame}
        setCheckedGame={setCheckedGame}
       />
      );
     })}
    </div>
   </div>
  </div>
 );
};

export { LiveCasinoSlot };
