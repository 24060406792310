import React, { useEffect, useRef } from "react";

import "./JackpotSlortar.scss";
import Odometer from "odometer";
import { useTypeSelector } from "srcm/hooks/useTypeSelector";

interface IJackpotSlortar {
  load: boolean;
}

const JackpotSlortar = ({ load }: IJackpotSlortar) => {
  const odometerRef = useRef(null);

  let baseOdometer: any;
  let amount = 3759958.81;

  useEffect(() => {
    if (!load) {
      baseOdometer = new Odometer({
        el: odometerRef.current,
        value: amount, // initial value
        format: "(,ddd).dd",
        theme: "default",
      });
    }
  }, [load]);

  function updateBaseAmount(newAmount: number) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseOdometer && baseOdometer.update(newAmount);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      updateBaseAmount(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (amount = amount + Math.floor(Math.random() * (55 - 15 + 1) + 15))
      );
    }, 5000);
    // clearing interval
    return () => clearInterval(timer);
  }, []);
  const MainTitle = useTypeSelector(
    (data) =>
      data?.settingsReducer?.content?.skinStyles.filter(
        (item) => item.key === "MainTitle"
      )[0]?.value
  );
  return (
    <div className="hm-row-bc " style={{ gridTemplateColumns: "12fr" }}>
      <div className="jackpot-banner">
        <div className="casinoJackpotWrapper-bc">
          {load ? (
            <div className="skeleton-loader-banner-height" />
          ) : (
            <div className="carousel">
              <div className="carousel-viewport">
                <div
                  className="carousel-container"
                  style={{ transform: "translate3d(0%, 0px, 0px)" }}
                >
                  <div
                    className="carousel-slide active-slide"
                    style={{ width: "100%" }}
                  >
                    <div className="casinoJackpotLevelEl-bc">
                      <h3 className="casinoJackpotLevelTitle-bc">
                        {/* {mainText}  */}
                        {MainTitle && MainTitle?.toUpperCase()} ÖZEL JACKPOT
                        <i className="casinoJackpotLevelInfo-bc bc-i-player-info" />
                      </h3>
                      <div
                        ref={odometerRef}
                        className="casinoJackpotLevelPrice-bc odometer odometer-auto-theme"
                        data-cur="₺"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { JackpotSlortar };
