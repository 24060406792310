import { userInfo } from "os";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";

import "./UserLine.scss";

const UserLine = () => {
  const useInfo = useTypeSelector((data) => data.accountInfoReducer.user);

  return (
    <div className="u-i-p-p-u-i-edit-button-bc">
      <span className="u-i-p-p-u-i-avatar-holder-bc">
        {/* {useInfo?.firstname[0] ? useInfo?.firstname[0] : ""}{" "}
        {useInfo?.lastname[0] ? useInfo?.lastname[0] : ""} */}
      </span>
      <p className="u-i-p-p-u-i-identifiers-bc">
        <span className="u-i-p-p-u-i-d-username-bc ellipsis">
          {/* {useInfo.firstname} {useInfo.lastname} */}
        </span>
        <span className="u-i-p-p-u-i-d-user-id-bc ellipsis">
          {/* {useInfo.id} */}
          <i className="u-i-p-p-u-i-d-user-id-copy-bc bc-i-copy" />
        </span>
      </p>
      <Link
        className="u-i-p-l-h-icon-bc right bc-i-small-arrow-right"
        to="/?profile=open& "
      />
    </div>
  );
};

export { UserLine };
