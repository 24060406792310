import { AdminPanel } from "services";

type getPaymentType = {
  inputValue: string;
  extra_data: {
    accountNumber: string | null;
    bankCode: string | null;
    description: string | null;
    iban: string | null;
    id: string | null;
    nameSurname: string | null;
    notes: string | null;
    tcNum: string | null;
    wallet_id: string | null;
  };
  service_id: number;
};

async function getPayment({
  inputValue,
  extra_data,
  service_id,
}: getPaymentType) {
  try {
    await AdminPanel.userWithdraw({
      data: {
        amount: Number(inputValue),
        currency: "TRY",
        extra_data: extra_data,
        service_id: service_id,
      },
    });
    alert("Çekim başarılıdır");
  } catch (e) {
    alert("Düşük bakiye");
  }
}

export { getPayment };
