import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  UserInformation,
  UserInformationBalanceType,
} from "../../common/types/AdminInfo/UserInformation";
import { userBalanceType } from "../../common/types/AdminInfo/balanceType/userBalanceType";
import { BannersList } from "../../common/types/Banners/BannersList";

interface UserInfo {
  email: string;
  id: string;
}

interface StateUser {
  user: UserInformation;
  role: string;
  banners: BannersList[];
}

const initialState: StateUser = {
  user: {
    id: 0,
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    api_user_id: 0,
    documents_verified: true,
    email_verified_at: null,
    profile: {
      phone: "",
      passport: "",
      country: "",
      state: "",
      city: null,
      address: "",
      zipcode: null,
      language: "",
      documents: null,
      identification_number: null,
      gender: 1,
      birthday: "",
    },
    is_crypto: 0,
    balance: {
      amount: 0,
      amount_bonus: 0,
      currency: "",
    },
    sportbook_token: "",
    active_bonus: null,
    pending_bonus: null,
    softswiss_freespin: false,
  },
  role: "unauth",
  banners: [],
};

const accountInfoSlice = createSlice({
  name: "accountInfo",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<UserInformation>) => {
      state.user = action.payload;
    },
    setBannersList: (state, action: PayloadAction<BannersList[]>) => {
      state.banners = action.payload;
    },
    setUserBalance: (
      state,
      action: PayloadAction<UserInformationBalanceType>
    ) => {
      state.user.balance = action.payload;
    },
  },
});

export const { setAuth, setUserInfo, setBannersList, setUserBalance } =
  accountInfoSlice.actions;
export default accountInfoSlice;
export type { UserInfo, StateUser };
export { initialState as initialStateBalance };
