import { AxiosResponse } from "axios";
import { CasinoGameType } from "common/types/Casino/CasinoGameType";
import { GameProvider } from "common/types/Casino/GameProvider";
import { login } from "common/types/Auth/login";
import { UserInformation } from "common/types/AdminInfo/UserInformation";
import { userDeposit } from "common/types/AdminInfo/userDeposit";
import { userPay, userPayBody } from "common/types/AdminInfo/userPay";
import { userBalanceType } from "common/types/AdminInfo/balanceType/userBalanceType";
import { userBetsType } from "common/types/AdminInfo/userBets";
import { register } from "common/types/Auth/register";
import { changePassword } from "common/types/Auth/changePassword";
import { FavoritCasinoResponceType } from "common/types/Casino/FavoritCasinoResponceType";
import { LastWinnersReguestType } from "common/types/Casino/LastWinnerType";
import { BannersList } from "common/types/Banners/BannersList";
import {
  axiosInstance,
  axiosInstanceTokenLess,
  axiosInstanceUnauth,
} from "httpApi";
import { promotionsResponceType } from "common/types/Promotions/promotionsResponceType";
import { ContentType } from "common/types/Content/ContentType";
import { PageType } from "common/types/PageType/PageType";
import { transactionsType } from "common/types/AdminInfo/transactionsType/transactionsType";
import { messagesListResponce } from "common/types/AdminInfo/messages/messagesListResponce";
import {
  messagesFullListResponce,
  messagesFullListResponceMessages,
} from "common/types/AdminInfo/messages/messagesFullListResponce";

class AuthService {
  static async login({
    username,
    password,
  }: login): Promise<AxiosResponse<{ message: string; token: string }>> {
    return axiosInstanceUnauth.post<{ message: string; token: string }>(
      "/login",
      {
        username,
        password,
      }
    );
  }
  static async register({
    address,
    affid,
    birthday,
    country,
    currency,
    cxd,
    email,
    firstname,
    gender,
    language,
    lastname,
    passport,
    password,
    password_confirmation,
    phone,
    promocode,
    state,
    username,
    zipcode,
    bpext,
  }: register): Promise<
    AxiosResponse<{
      message: string;
      token: string;
    }>
  > {
    return axiosInstanceUnauth.post<{
      message: string;
      token: string;
    }>("/register", {
      address,
      affid,
      birthday,
      country,
      currency,
      cxd,
      email,
      firstname,
      gender,
      language,
      lastname,
      passport,
      password,
      password_confirmation,
      phone,
      promocode,
      state,
      username,
      zipcode,
      bpext,
    });
  }
  static async changePassword({
    current_password,
    password,
    password_confirmation,
  }: changePassword): Promise<AxiosResponse<string>> {
    return axiosInstance.post<string>("/auth/change-password", {
      current_password,
      password,
      password_confirmation,
    });
  }
}

class CasinoInfo {
  static async providers(
    searchParams?: string
  ): Promise<AxiosResponse<{ data: GameProvider }>> {
    return axiosInstanceUnauth.get<{ data: GameProvider }>(
      `/providers${searchParams}`
    );
  }

  static async categories(searchParams: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get<any>(`/categories-with-games${searchParams}`);
  }

  static async games(searchParams: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get<any>(`/categories-with-games${searchParams}`);
  }

  static async mainPageGames(): Promise<AxiosResponse<any>> {
    return axiosInstanceTokenLess.get<any>(`/home-page-games-flat?is_all=1`);
  }

  static async searchGames(searchParams: string): Promise<AxiosResponse<any>> {
    return axiosInstance.get<any>(`/search-games${searchParams}`);
  }
  static async setFavoriteGame(data: {
    game_id: number;
    type: number;
  }): Promise<{ data: FavoritCasinoResponceType }> {
    return axiosInstance
      .post<FavoritCasinoResponceType>(`/add-to-favorite`, data)
      .then((response) => ({ data: response.data }));
  }

  static async favoriteGames(): Promise<{ data: FavoritCasinoResponceType[] }> {
    return axiosInstance
      .get<FavoritCasinoResponceType[]>(`/favorites-games`)
      .then((response) => ({ data: response.data }));
  }
  static async casinoPlay(
    searchParams: string
  ): Promise<AxiosResponse<{ data: CasinoGameType }>> {
    return axiosInstance.get<{ data: CasinoGameType }>(
      `/casino${searchParams}`
    );
  }
  static async casinoPlayDemo(
    searchParams: string
  ): Promise<AxiosResponse<{ data: CasinoGameType }>> {
    return axiosInstanceTokenLess.get<{ data: CasinoGameType }>(
      `/casino${searchParams}`
    );
  }
  static async casinoPlayFreespins(
    searchParams: string
  ): Promise<AxiosResponse<string>> {
    return axiosInstance.get<string>(
      `/user-dashboard/freespin/request?launch_code=${searchParams}&platform=dekstop`
    );
  }
  static async lastWinners(): Promise<AxiosResponse<LastWinnersReguestType>> {
    return axiosInstance.post<LastWinnersReguestType>("/top-winners");
  }
  static async MainBanners(
    lang: string
  ): Promise<AxiosResponse<{ data: BannersList[] }>> {
    return axiosInstanceTokenLess.get<{ data: BannersList[] }>(
      `/banners?lang=${lang}`
    );
  }
  static async sportbookStartSession(
    lang: string
  ): Promise<AxiosResponse<string>> {
    return axiosInstance.get<string>(
      `/sportbook-start-session?lang=${lang}&live=false`
    );
  }

  static async promotions(
    promoId: string
  ): Promise<{ data: promotionsResponceType }> {
    return axiosInstance
      .get<{ data: promotionsResponceType }>(`/promotions/${promoId}`)
      .then((response) => ({ data: response.data.data }));
  }

  static async page(id: string): Promise<{ data: PageType }> {
    return axiosInstance
      .get<{ data: PageType }>(`/page/${id}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async content(): Promise<AxiosResponse<ContentType>> {
    return axiosInstance.get<ContentType>(`/content`);
  }
}

class AdminPanel {
  static async userInfo(token?: string): Promise<{ data: UserInformation }> {
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
    return axiosInstance
      .get<{ data: UserInformation }>("user", { headers })
      .then((response) => ({ data: response.data.data }));
  }

  static async userBalance(): Promise<{ data: userBalanceType }> {
    return axiosInstance
      .get<{ data: userBalanceType }>("/update-user-balance")
      .then((response) => ({ data: response.data.data }));
  }

  static async userBets(params: string): Promise<AxiosResponse<userBetsType>> {
    return axiosInstance.get<userBetsType>(`/user-dashboard/bets${params}`);
  }

  static async userDeposit(params: string): Promise<{ data: userDeposit[] }> {
    return axiosInstance
      .get<{ data: userDeposit[] }>(`/payment-methods${params}`)
      .then((response) => ({ data: response.data.data }));
  }

  static async userPay({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<any>> {
    return axiosInstance.post<any>(
      `/user-dashboard/deposits/set${params}`,
      data
    );
  }

  static async userPayManual({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<any>> {
    return axiosInstance.post<any>(
      `/user-dashboard/deposits/set-manual${params}`,
      data
    );
  }

  static async userWithdraw({
    data,
  }: {
    data: {
      amount: number;
      currency: string;
      extra_data: {
        accountNumber: string | null;
        bankCode: string | null;
        description: string | null;
        iban: string | null;
        id: string | null;
        nameSurname: string | null;
        notes: string | null;
        tcNum: string | null;
        wallet_id: string | null;
      };
      service_id: number;
    };
  }): Promise<{ data: userPayBody }> {
    return axiosInstance
      .post<{ data: userPayBody }>(`/user-dashboard/withdrawals/set`, data)
      .then((response) => ({ data: response.data.data }));
  }

  static async deleteBonus({
    data,
  }: {
    data: number;
  }): Promise<{ data: string }> {
    return axiosInstance
      .post<{ data: string }>(`/user-dashboard/delete-bonus/${data}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async transactions(
    params: string
  ): Promise<{ data: transactionsType[] }> {
    return axiosInstance
      .get<{ data: transactionsType[] }>(
        `/user-dashboard/transactions${params}`
      )
      .then((response) => ({ data: response.data.data }));
  }

  static async ticketList(
    params: string
  ): Promise<{ data: messagesListResponce[] }> {
    return axiosInstance
      .get<{ data: messagesListResponce[] }>(`/user-dashboard/tickets${params}`)
      .then((response) => ({ data: response.data.data }));
  }

  static async newTicketCreate(data: {
    department_id: number;
    title: string;
    message: string;
  }): Promise<AxiosResponse<{ message: string }>> {
    return axiosInstance.post<{ message: string; token: string }>(
      "/user-dashboard/tickets",
      data
    );
  }

  static async showTicket(
    params: string
  ): Promise<{ data: messagesFullListResponce }> {
    return axiosInstance
      .get<{ data: messagesFullListResponce }>(
        `/user-dashboard/tickets/${params}`
      )
      .then((response) => ({ data: response.data.data }));
  }

  static async newMessageCreate(data: {
    message: string;
    ticket_id: string;
  }): Promise<{ data: messagesFullListResponceMessages }> {
    return axiosInstance
      .post<{ data: messagesFullListResponceMessages }>(
        `/user-dashboard/messages`,
        data
      )
      .then((response) => ({ data: response.data.data }));
  }

  static async TicketClose(params: number): Promise<{ data: any }> {
    return axiosInstance
      .delete<{ data: any }>(`/user-dashboard/tickets/${params}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async setCheckDomain(data: { url: string }): Promise<any> {
    return axiosInstance
      .post<any>(`/check-future-domain`, data)
      .then((response) => ({ data: response.data }));
  }
}

export { AuthService, CasinoInfo, AdminPanel };
