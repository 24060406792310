import { t } from "i18next";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useTypeSelector } from "../../../../hooks/useTypeSelector";

import "./SettingsModal.scss";
import { setLanguage } from "../../../../store/reducers/settingsReducer";

interface ISettingsModal {
  setSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingsModal = ({ setSettingsOpen }: ISettingsModal) => {
  const { en } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const language = useTypeSelector((data) => data.settingsReducer.language);

  const handleItemSelect = (item: React.ChangeEvent<HTMLSelectElement>) => {
    let languageSelected = language;
    if (item.target.value === "tr") {
      languageSelected = {
        languageShort: "tr",
        languageName: "Türkçe",
        languageAbs: "TUR",
        languageFlag: "turkey",
      };
      window.location.reload();
    } else if (item.target.value === "en") {
      languageSelected = {
        languageShort: "en",
        languageName: "English",
        languageAbs: "ENG",
        languageFlag: "unitedkingdom",
      };
      window.location.reload();
    }
    dispatch(setLanguage(languageSelected));
    const currentPath = location.pathname;
    const newPath = en
      ? currentPath.replace(en, languageSelected.languageShort)
      : currentPath;
    navigate(newPath);
    setSettingsOpen(false);
    window.location.reload();
  };
  const handleCloseSetting = () => {
    setSettingsOpen(false);
  };
  return (
    <div
      className="overlay-sliding-wrapper-bc  "
      style={{ transform: "translateY(0px)", opacity: 1 }}
    >
      <div
        className="overlay-sliding-w-c-content-slider-bc"
        data-scroll-lock-scrollable=""
      >
        <div className="back-nav-bc" onClick={handleCloseSetting}>
          <i className="back-nav-icon-bc bc-i-round-arrow-left" />
          <span className="back-nav-title-bc ellipsis">
            {t("admin.Settings")}
          </span>
        </div>
        <div className="settings-selectors-bc">
          <div className="settings-selectors-item">
            <div className="form-control-bc select  filled">
              <label className="form-control-label-bc inputs">
                <select className="form-control-select-bc ellipsis">
                  <option value={0}>{t("admin.Decimal")}</option>
                  <option value={1}>{t("admin.Fractional")}</option>
                  <option value={2}>{t("admin.American")}</option>
                  <option value={3}>{t("admin.HongKong")}</option>
                  <option value={4}>{t("admin.Malay")}</option>
                  <option value={5}>{t("admin.Indo")}</option>
                </select>
                <i className="form-control-icon-bc bc-i-small-arrow-down" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.OddsFormat")}
                </span>
              </label>
            </div>
            <div className="form-control-bc select  filled">
              <label className="form-control-label-bc inputs">
                <i
                  className={`select-icon-bc ftr-lang-bar-flag-bc flag-bc ${language.languageFlag}`}
                />
                <select
                  className="form-control-select-bc ellipsis"
                  onChange={handleItemSelect}
                  defaultValue={language.languageAbs}
                >
                  {language.languageShort === "tr" ? (
                    <>
                      <option value="tr">Türkçe</option>
                      <option value="en">English</option>
                    </>
                  ) : null}
                  {language.languageShort === "en" ? (
                    <>
                      <option value="en">English</option>
                      <option value="tr">Türkçe</option>
                    </>
                  ) : null}
                </select>
                <i className="form-control-icon-bc bc-i-small-arrow-down" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Language")}
                </span>
              </label>
            </div>
            <div className="form-control-bc select  filled">
              <label className="form-control-label-bc inputs">
                <select className="form-control-select-bc ellipsis">
                  <option value="12h">12h</option>
                  <option value="24h">24h</option>
                </select>
                <i className="form-control-icon-bc bc-i-small-arrow-down" />
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Timeformat")}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SettingsModal };
