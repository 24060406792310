import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import "./BalanceManagement.scss";

interface IBalanceManagement {
  setSelectedGroup: React.Dispatch<React.SetStateAction<string>>;
}

const BalanceManagement = ({ setSelectedGroup }: IBalanceManagement) => {
  const handleBack = () => {
    setSelectedGroup("");
  };
  return (
    <div className="u-i-p-links-lists-holder-bc">
      <div className="u-i-p-l-head-bc" onClick={handleBack}>
        <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-left" />
        <span className="u-i-p-l-h-title-bc ellipsis">
          {t("admin.BalanceManagement")}
        </span>
      </div>
      <div className="u-i-p-l-body-bc">
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search:
              "?profile=open&account=balance&page=deposit&selectedGroup=all",
          }}
        >
          <i className="user-nav-icon bc-i-circle-dollar" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.Deposit")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search:
              "?profile=open&account=balance&page=deposit&selectedGroup=all",
          }}
        >
          <i className="user-nav-icon bc-i-withdraw" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.Withdrawal")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=balance&page=history",
          }}
        >
          <i className="user-nav-icon bc-i-history" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.TransactionHistory")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
        <Link
          className="u-i-p-l-head-bc"
          to={{
            search: "?profile=open&account=balance&page=withdraws",
          }}
        >
          <i className="user-nav-icon bc-i-withdraws" />
          <span className="u-i-p-l-h-title-bc ellipsis">
            {t("admin.WithdrawStatus")}
          </span>
          <i className="  count-blink-even " data-badge="" />
          <i className="u-i-p-l-h-icon-bc bc-i-small-arrow-right" />
        </Link>
      </div>
    </div>
  );
};

export { BalanceManagement };
