import axios from "axios";
const baseURL = process.env.REACT_APP_API_ORIGIN_URL;
const brand = process.env.REACT_APP_API_ORIGIN_BRAND;
const token = process.env.REACT_APP_API_ORIGIN_TOKEN;

const axiosInstanceUnauth = axios.create({
  baseURL: `${baseURL}/api/v1`,
});

axiosInstanceUnauth.interceptors.request.use(
  (config) => {
    const code = "en";
    const newConfig = { ...config };
    newConfig.headers.lang = code;
    return newConfig;
  },
  (error) => Promise.reject(error)
);

axiosInstanceUnauth.defaults.headers.common.brand = brand;

if (token) {
  axiosInstanceUnauth.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export { axiosInstanceUnauth };
