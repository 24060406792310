import React from "react";

import "./BannersBetzulaAnimated.scss";

import bannerMock1 from "./mock/1.jpg"
import bannerMock2 from "./mock/2.png"
import bannerMock3 from "./mock/3.png"
import bannerMock4 from "./mock/4.png"
import bannerMock5 from "./mock/5.png"
import bannerMock6 from "./mock/6.png"
import { Link } from "react-router-dom";
import { useTypeSelector } from "srcm/hooks/useTypeSelector";

const BannersBetzulaAnimated = () => {
  const lang = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
 return (
  <div className="hm-row-bc   " style={{ gridTemplateColumns: "12fr" }}>
   <div className="pb-component-wrapper">
    <div className="product-banner-container-bc col-5 product-banner-without-titles">
     <Link
      target="_self"
      className="product-banner-info-bc product-banner-bc"
      to={`/${lang}/casino/slots/-1?openGames=softswiss&gameNames=plink`}
     >
      <img
       className="product-banner-img-bc"
       src={bannerMock1}
       alt={bannerMock1}
      />
     </Link>
     <Link
      target="_self"
      className="product-banner-info-bc product-banner-bc"
      to={`/${lang}/casino/slots/-1?openGames=spribe&gameNames=aviator`}
     >
      <img
       className="product-banner-img-bc"
       src={bannerMock2}
       alt={bannerMock2}
      />
     </Link>
     <Link
      target="_self"
      className="product-banner-info-bc product-banner-bc"
      to={`/${lang}/casino/slots/-1?openGames=fazi&gameNames=fazi-classicluckyspin`}
     >
      <img
       className="product-banner-img-bc"
       src={bannerMock3}
       alt={bannerMock3}
      />
     </Link>
     <Link
      target="_self"
      className="product-banner-info-bc product-banner-bc"
      to={`/${lang}/casino/slots/-1?openGames=habanero&gameNames=hb-sgcoyotecrash`}
     >
      <img
       className="product-banner-img-bc"
       src={bannerMock4}
       alt={bannerMock4}
      />
     </Link>
     <Link
      target="_self"
      className="product-banner-info-bc product-banner-bc"
      to={`/${lang}/casino/slots/-1?openGames=kagaming&gameNames=ka-blastman`}
     >
      <img
       className="product-banner-img-bc"
       src={bannerMock5}
       alt={bannerMock5}
      />
     </Link>
     <Link
      target="_self"
      className="product-banner-info-bc product-banner-bc"
      to={`/${lang}/casino/slots/-1?openGames=spribe&gameNames=aviator`}
     >
      <img
       className="product-banner-img-bc"
       src={bannerMock6}
       alt={bannerMock6}
      />
     </Link>
    </div>
   </div>
  </div>
 );
};

export { BannersBetzulaAnimated };
