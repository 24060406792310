import { t } from "i18next";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { changePassword } from "../../../../../../../common/types/Auth/changePassword";
import { AuthService } from "../../../../../../../services";

import "./ChangePasswordPage.scss";
import { ConfirmPassword } from "./Inputs/ConfirmPassword/ConfirmPassword";
import { CurrentPassword } from "./Inputs/CurrentPassword/CurrentPassword";
import { NewPassword } from "./Inputs/NewPassword/NewPassword";
import { setAuth } from "../../../../../../../store/reducers/accountInfoReducer";

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<changePassword>();

  const onSubmit = (data: any) => {
    async function setChangePassword() {
      try {
        await AuthService.changePassword({
          current_password: data.current_password,
          password: data.password,
          password_confirmation: data.password_confirmation,
        });
        dispatch(setAuth("unauth"));
        localStorage.removeItem("token");
        alert("succesful changed password");
      } catch (error) {
        console.error(error);
      }
    }
    setChangePassword();
  };
  return (
    <div className="u-i-edit-change-password-page-bc">
      <div className="u-i-e-ch-p-content-bc u-i-common-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <CurrentPassword register={register} watch={watch} />
          <NewPassword register={register} watch={watch} />
          <ConfirmPassword register={register} watch={watch} />
          <div className="u-i-p-c-footer-bc">
            <button
              className="btn a-color right-aligned "
              type="submit"
              title="Change Password"
              disabled={Object.keys(errors).length !== 0}
            >
              <span>{t("admin.ChangePassword")}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { ChangePasswordPage };
