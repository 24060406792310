import { messagesListResponce } from "common/types/AdminInfo/messages/messagesListResponce";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminPanel } from "services";
import { convertData } from "utils/convertData";

interface IMessage {
 messageInfo: messagesListResponce;
 getUsers: () => void;
}

const Message = ({ messageInfo, getUsers }: IMessage) => {
 const navigate = useNavigate();
 const location = useLocation();

 const addIdPageParam = (idPageValue: string) => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.set("idPage", idPageValue);
  searchParams.set("page", "inbox-chat");
  navigate(`${location.pathname}?${searchParams.toString()}`, {
   replace: true,
  });
 };

 const handleMoveModal = () => {
  addIdPageParam(String(messageInfo.id));
 };

 const { formattedDate } = convertData(messageInfo.updated_at);

 const deleteTicket = () => {
  AdminPanel.TicketClose(messageInfo.id)
   .then(() => {
    try {
     getUsers();
    } catch (e) {
     console.error(e);
    }
   })
   .catch((e) => console.error(e));
 };

 return (
  <div className="message-block-bc" data-status="read">
   <div className="message-title-block">
    <h3 className="message-title-bc ">{messageInfo.title.en}</h3>
    <div className="message-status-container">
     <div
      className={`message-status-info opened ${
       messageInfo.status_id === 1 ? "opened" : "closed"
      }`}
     >
      {messageInfo.status_id === 1 ? "Opened" : "Closed"}
     </div>
     <button
      className={`message-status-button ${
       messageInfo.status_id === 2 ? "hidden" : ""
      }`}
      onClick={deleteTicket}
     >
      Close
     </button>
    </div>
    {/* <i className="message-remove-icon bc-i-clear" title="Delete" /> */}
   </div>
   <div className="message-more-time-bc">
    <time className="message-time-bc">{formattedDate}</time>
    <i
     className="message-more-icon bc-i-double-arrow-top"
     onClick={handleMoveModal}
    />
   </div>
  </div>
 );
};

export { Message };
