import { t } from "i18next";
import React from "react";

import "./VerifyAccountPage.scss";

const VerifyAccountPage = () => {
  return (
    <div
      className="u-i-edit-verify-account-page-bc"
      data-scroll-lock-scrollable=""
    >
      <div>
        <div className="u-i-p-info-text-holder-bc">
          <div className="u-i-p-info-text-bc">
            {t("admin.verifAccoutRouls")}
          </div>
        </div>
        <div className="verify-form">
          <div className="u-i-p-control-item-holder-bc">
            <div className="form-control-bc select has-icon valid filled">
              <label className="form-control-label-bc inputs">
                <select
                  className="form-control-select-bc active"
                  name="selectedImageType"
                >
                  <option value={1}>{t("admin.BankSlip")}</option>
                  <option value={2}>Document</option>
                  <option value={3}>Passport/ ID card</option>
                  <option value={4}>Drivers License</option>
                  <option value={5}>IBAN</option>
                  <option value={6}>Social Card ID</option>
                  <option value={7}>Other</option>
                  <option value={15}>Selfie</option>
                </select>
                <i className="form-control-icon-bc bc-i-small-arrow-down" />
                <i className="form-control-input-stroke-bc" />
                <span className="form-control-title-bc ellipsis">
                  Please select your type of ID
                </span>
              </label>
            </div>
          </div>
          <div className="u-i-p-control-item-holder-bc">
            <div className="form-control-bc has-icon file filled">
              <label className="form-control-label-bc form-control-label-file-bc inputs">
                <input
                  type="file"
                  className="form-control-input-bc"
                  name="passportImage"
                  step={0}
                  defaultValue=""
                />
                <span className="form-control-icon-holder-bc">
                  <i className="form-control-icon-bc bc-i-upload" />
                </span>
                <span className="form-control-filename-bc ellipsis">
                  {t("admin.ChooseFile")}
                </span>
                <span className="form-control-title-bc ellipsis">
                  {t("admin.Attachfile")}
                </span>
              </label>
            </div>
          </div>
          <div className="u-i-p-c-footer-bc-verify">
            <button
              className="btn a-color big-btn "
              type="submit"
              title="Verify"
              disabled={true}
            >
              <span>{t("admin.Verify")}</span>
            </button>
          </div>
        </div>
      </div>
      <span className="u-i-e-v-a-t-title-bc">
        {t("admin.Uploadeddocuments")}
      </span>
      <div className="u-i-p-info-light-text-holder-bc">
        <p className="u-i-p-info-light-text-bc">
          {t("admin.Therearenouploadeddocuments")}.
        </p>
      </div>
    </div>
  );
};

export { VerifyAccountPage };
