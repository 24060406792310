import { t } from "i18next";
import React, { useEffect, useState } from "react";

import "./SubjectInput.scss";

interface ISubjectInput {
 watch: any;
 register: any;
}

const SubjectInput = ({ watch, register }: ISubjectInput) => {
 const inputValue = watch("title");
 const [inputError, setInputError] = useState(false);
 const [inputOpen, setInputOpen] = useState(false);
 const handleFocus = () => {
  setInputOpen(true);
 };
 const handleBlur = () => {
  if (inputValue.length > 0) {
   setInputOpen(true);
  } else {
   setInputOpen(false);
   setInputError(true);
  }
 };
 useEffect(() => {
  setInputError(false);
  if (inputValue.length > 0) {
   setInputOpen(true);
  }
 }, [inputValue]);
 return (
  <div className="u-i-p-control-item-holder-bc">
   <div
    className={`form-control-bc default ${inputOpen ? "focused" : ""} ${
     inputError ? "invalid" : ""
    }`}
   >
    <label className="form-control-label-bc inputs">
     <input
      type="text"
      className="form-control-input-bc"
      name="subject"
      {...register("title", { required: true })}
      onFocus={handleFocus}
      onBlur={handleBlur}
     />
     <i className="form-control-input-stroke-bc" />
     <span className="form-control-title-bc ellipsis">
      {t("admin.Subject")}
     </span>
    </label>
    {inputError ? (
     <div className="form-control-message-holder-bc">
      <span className="form-control-message-bc">
       {t("admin.Thisfieldisrequired")}
      </span>
     </div>
    ) : null}
   </div>
  </div>
 );
};

export { SubjectInput };
