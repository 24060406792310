const convertData = (date: string) => {
 const apiDate = new Date(date);
 const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
 ];
 const formattedDate = `${apiDate.getDate()} ${
  months[apiDate.getMonth()]
 } ${apiDate.getFullYear()}`;
 return { formattedDate };
};

export { convertData };
