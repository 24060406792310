import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../../components/layout";
import { PromotionsType } from "../../components/promotions/PromoitonsType/PromotionsType";

import "./Promotions.scss";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import {
  BananersEnum,
  BannersList,
} from "../../common/types/Banners/BannersList";
import { useNavigate, useParams } from "react-router-dom";
import { CasinoInfo } from "../../services";
import { promotionsResponceType } from "../../common/types/Promotions/promotionsResponceType";

import parse from "html-react-parser";

const Promotions = () => {
  const bannersList = useTypeSelector(
    (data) => data.accountInfoReducer.banners
  );
  const [contentImage, setContentImage] = useState("");
  const [contentResponce, setContentResponce] =
    useState<promotionsResponceType | null>(null);

  const navigate = useNavigate();

  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );

  const handleCloseInfo = () => {
    setContentImage("");
    setContentResponce(null);
    navigate(`/${language}/promotions/all`);
  };

  const handleOpenInfo = async (item: BannersList) => {
    try {
      const res = await CasinoInfo.promotions(item.url || "");
      setContentResponce(res.data);
      setContentImage(item.desktop_image.s3);
      navigate(`/${language}/promotions/all/${item.url}`);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpenInfoDefault = async (item: string) => {
    try {
      const res = await CasinoInfo.promotions(item);
      setContentResponce(res.data);
      setContentImage(res.data.list_image);
      navigate(`/${language}/promotions/all/${item}`);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (id) {
      handleOpenInfoDefault(id);
    }
  }, []);

  const { id } = useParams();

  const wrapperRef = useRef(null);
  const handleTapOutside = (event: MouseEvent | TouchEvent) => {
    if (
      wrapperRef.current &&
      !(wrapperRef.current as HTMLElement).contains(event.target as Node)
    ) {
      handleCloseInfo();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleTapOutside);
    document.addEventListener("touchstart", handleTapOutside);
    return () => {
      document.removeEventListener("mousedown", handleTapOutside);
      document.removeEventListener("touchstart", handleTapOutside);
    };
  }, []);

  return (
    <Layout containerStyles="is-home-page " navHeader="promotions">
      <div className="layout-content-holder-bc">
        <PromotionsType />
        <section className="promotionsList-bc custom-promoitons-list">
          {bannersList
            .filter((item) => item.type === BananersEnum.Promotions)
            .map((item) => {
              return (
                <article
                  className="promotionsListEl-bc"
                  key={item.id}
                  onClick={() => handleOpenInfo(item)}
                >
                  <img
                    className="promotionsArticleThumbnail-bc"
                    alt=""
                    title="Drop & Wins"
                    src={item.mobile_image.s3}
                  />
                  <h3 className="promotions-article-title ellipsis">
                    {item.title}
                  </h3>
                </article>
              );
            })}
          {contentResponce && id ? (
            <div
              className="overlay-sliding-wrapper-bc promotion-details-popup "
              style={{ transform: "translateY(0px)", opacity: 1 }}
            >
              <div
                className="overlay-sliding-w-c-content-slider-bc"
                style={{ flexDirection: "column" }}
              >
                <div className="back-nav-bc" onClick={handleCloseInfo}>
                  <i className="back-nav-icon-bc bc-i-round-arrow-left" />
                  <span className="back-nav-title-bc ellipsis">
                    {contentResponce.title}
                  </span>
                </div>
                <div
                  className="promotion-details-container"
                  style={{ flexDirection: "column" }}
                >
                  <div className="promotion-details-header">
                    <img
                      src={contentResponce.list_image}
                      className="promotion-details-img"
                      alt=""
                      title="Drop & Wins"
                    />
                  </div>
                  {contentResponce.rules
                    ? parse(contentResponce.rules)
                    : contentResponce.rules}
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </div>
    </Layout>
  );
};

export { Promotions };
