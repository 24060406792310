import { messagesFullListResponceMessages } from "common/types/AdminInfo/messages/messagesFullListResponce";
import { useTypeSelector } from "hooks/useTypeSelector";
import React from "react";
import { convertDateTime } from "utils/convertDateTime";

interface IMessageChat {
 messageInfo: messagesFullListResponceMessages;
}

const MessageChat = ({ messageInfo }: IMessageChat) => {
 const formattedDate = convertDateTime(messageInfo.updated_at);
 //  const lang = useTypeSelector(
 //     (data) => data.settingsReducer.language.languageShort
 //    );
 return (
  <div className={`message-info-contnainer ${messageInfo.type}`}>
   <div className="message-text">{messageInfo.message.en}</div>
   <div className="message-date">{formattedDate}</div>
  </div>
 );
};

export { MessageChat };
