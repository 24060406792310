import { t } from "i18next";
import React from "react";

import "./WithdrawsPage.scss";

const WithdrawsPage = () => {
  return (
    <div className="historyLayoutWrapper-bc">
      <div className="historyList-bc ">
        <p className="empty-b-text-v-bc">{t("admin.NoWithdrawalInformation")}</p>
      </div>
    </div>
  );
};

export { WithdrawsPage };
