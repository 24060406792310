import React from "react";
import { FooterSetteings } from "./components/footerSetteings/FooterSetteings";

import parse from "html-react-parser";

import "./Footer.scss";
import { useTypeSelector } from "../../../hooks/useTypeSelector";
import { BananersEnum } from "../../../common/types/Banners/BannersList";
import { FooterBetzula } from "./components/footerBetzula/FooterBetzula";
import { Link } from "react-router-dom";
import { FooterLink } from "./components/footerlinks/FooterLink";
import mockLisence from "accets/images/png/mocklisence/photo_2024-11-07 01.04.40.jpeg";

const Footer = () => {
  const { content } =
    useTypeSelector((data) => data?.settingsReducer?.content?.mobileFooter) ||
    [];
  const FooterTopBaner = useTypeSelector((data) =>
    data.accountInfoReducer.banners.filter(
      (item) => item.type === BananersEnum.FooterTopBaner
    )
  );
  const FooterCenterBaner = useTypeSelector((data) =>
    data.accountInfoReducer.banners.filter(
      (item) => item.type === BananersEnum.FooterCenterBaner
    )
  );
  const FooterBottomBaner = useTypeSelector((data) =>
    data.accountInfoReducer.banners.filter(
      (item) => item.type === BananersEnum.FooterBottomBaner
    )
  );

  const FooterPaymentBanners = useTypeSelector((data) =>
    data.accountInfoReducer.banners.filter(
      (item) => item.type === BananersEnum.FooterPaymentBanners
    )
  );
  const mainText =
    useTypeSelector(
      (data) =>
        data?.settingsReducer?.content?.skinStyles.filter(
          (item) => item.key === "title-casino"
        )[0]?.value
    ) || null;
  const campaignIds = Comm100API.get("livechat.campaignIds");
  const campaignId = campaignIds[0];

  const handleOpenChat = () => {
    Comm100API.do("livechat.button.click", campaignId);
  };
  return (
    <div className="layout-footer-holder-bc">
      <div className="footerWrapper">
        <div className="footerContainerWrapper">
          <div className="footerContainer">
            <div className="footerHeader">
              <div className="footerTopButtonGroup">
                <button
                  className="button outlined small supportButton "
                  type="button"
                  onClick={() => handleOpenChat()}
                >
                  <span className="ellipsis">DESTEK İLE İLETİŞİME GEÇİN</span>
                </button>
              </div>
              <div className="footerInnerLeftCol">
                <ul className="footerSocialLinks">
                  <li className="footerSocialLink">
                    <Link to="#" target="_blank" className="" rel="noreferrer">
                      <i className="bc-i-youtube" />
                    </Link>
                  </li>
                  <li className="footerSocialLink">
                    <Link to="#" target="_blank" className="" rel="noreferrer">
                      <i className="bc-i-twitter" />
                    </Link>
                  </li>
                  <li className="footerSocialLink">
                    <Link to="#" target="_blank" className="" rel="noreferrer">
                      <i className="bc-i-telegram" />
                    </Link>
                  </li>
                  <li className="footerSocialLink">
                    <Link to="#" target="_blank" className="" rel="noreferrer">
                      <i className="bc-i-instagram" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footerInfoColumn">
                <div className="selectHolder big languageDropdown  filled">
                  <div className="selectContainer selectOption ">
                    <div className="selectIconBlock">
                      <i className="bc-i-small-arrow-down" />
                    </div>
                    <div className="selectImitation">
                      <div className="flagImgSelectBlock">
                        <div className="selectIconBc flagImg turkey" />
                      </div>
                      <span className="selectItemTitle">Türkçe</span>
                    </div>
                  </div>
                  <select className="selectMobile">
                    <option value="eng">English</option>
                    <option value="tur">Türkçe</option>
                  </select>
                </div>
              </div>
            </div>
            <FooterLink />
            <div className="footerSliderGroup">
              <div className="footerSlider">
                <h4 className="footerSliderTitle">
                  Regulations &amp; Partners
                </h4>
                <div className="footerSliderGroup">
                  <div className="footerSliderSingleElWrapper">
                    <div className="footerSliderSingleEl">
                      <div className="ftr-copy-rights-bc">
                        <p>
                          <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to="betkorner.com"
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            betkorner.com
                          </Link>{" "}
                          Johan Van Walbeeckplein 24, Curacao adresinde kayıtlı,
                          8048/JAZ2020-032 oyun lisansı bulunan ve oyun
                          yazılımını işletmek için tüm haklara sahip olan Seguri
                          N.V., sicil numarası 153142 olan tarafından
                          işletilmektedir.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="footerSliderSingleElWrapper">
                    <div className="footerSliderSingleEl">
                      <Link
                        to="#"
                        target="_blank"
                        className="footerSliderImage"
                        rel="noreferrer"
                      >
                        <img
                          loading="lazy"
                          src="https://cmsbetconstruct.com/storage/medias/betzula-18748519/media_18748519_c86f12f7611b488b3b3180fa46031520.png"
                          alt=""
                        />
                      </Link>
                      <Link className="footerSliderImage" to="#">
                        <img
                          loading="lazy"
                          src="https://cmsbetconstruct.com/content/images/345ea2e23ad58c2dc5c968d542fea7d9_1063_media.png"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="footerSliderSingleElWrapper">
                    <div className="footerSliderSingleEl">
                      <Link
                        to="http://cert-gcb-betkorner.com/"
                        target="_blank"
                        className="footerSliderImage"
                        rel="noreferrer"
                      >
                        <img loading="lazy" src={mockLisence} alt="" />
                      </Link>
                    </div>
                  </div>
                  <FooterBetzula />
                </div>
              </div>
              <div className="footerSlider">
                <h4 className="footerSliderTitle">Payments</h4>
                <div
                  className="horizontalSliderWrapper"
                  data-scroll-lock-scrollable=""
                >
                  <div className="horizontalSliderRow ">
                    <Link
                      className="horizontalSliderElem"
                      id="10966_FulgurPayUSDT_T"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/10966.png"
                        alt="USDT TRC20"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="13750_Aninda_Kripto"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/13750.png"
                        alt="Aninda Kripto"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="13045_JustPays"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/13045.png"
                        alt="Hızlı Havale"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="11423_Bigpayss_Bank"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/11423.png"
                        alt="Bigpayss Bank Transfer"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="11979_KuzeyPayHavale"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/11979.png"
                        alt="Tiktakhavale"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="8265_VevoPayHavale"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/8265.png"
                        alt="Vevopay Havale"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="4672_Hizlipapara"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/4672.png"
                        alt="Hizlipapara"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="1445_AnindaPapara"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/1445.png"
                        alt="AnindaPapara"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="12378_WexaPay"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/12378.png"
                        alt=" Fastx"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="7287_VevoPayPapara"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/7287.png"
                        alt="Vevopay"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="4338_Fixturka"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/4338.png"
                        alt="Payfix"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="13122_CepPay"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/13122.png"
                        alt="Payco"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="13286_Aninda_KrediKarti"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/13286.png"
                        alt="Aninda_KrediKarti"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="13142_Aninda_Mefete"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/13142.png"
                        alt="Aninda Mefete"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="8544_VevoPayMFT"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/8544.png"
                        alt="VevoPay Mefete"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="9431_VevoPayParazula"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/9431.png"
                        alt="Parazula"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="13035_SeriPopy"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/13035.png"
                        alt="SeriPopy"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="13754_LoginPayCreditCard"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/13754.png"
                        alt="LoginPayCreditCard"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="3578_AnindaQR"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/3578.png"
                        alt="AnindaQR"
                        loading="lazy"
                      />
                    </Link>
                    <Link
                      className="horizontalSliderElem"
                      id="10423_FulgurPay"
                      to={{
                        search: "?profile=open&account=balance&page=deposit",
                      }}
                    >
                      <img
                        className="horizontalSliderImg"
                        src="https://cms.fjykjczttspydxcx.com/content/images/payments/custom/18748519/10423.png"
                        alt="Cryptocurrency"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="footerCopyrightBlock">
              <div className="footerWarning">
                <span className="footerWarningAge">18+</span>
              </div>
              <p className="footerCopyrights">2014 - 2024 Betkorner</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };
