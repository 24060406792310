import React, { useEffect, useRef, useState } from "react";
import { CasinoIconGameType } from "../../common/types/Casino/CasinoIconGameType";
import { CasinoList } from "../../components/casinoList/CasinoList";
import { JackpotLeavelBlock } from "../../components/jackpot/JackpotLeavelBlock/JackpotLeavelBlock";
import { JackpotLeavleList } from "../../components/jackpot/JackpotLeavleList/JackpotLeavleList";
import { JackpotSearch } from "../../components/jackpot/JackpotSearch/JackpotSearch";
import { JackpotTopBar } from "../../components/jackpot/JackpotTopBar/JackpotTopBar";
import { Layout } from "../../components/layout";
import { CasinoInfo } from "../../services";

import "./Jackpot.scss";

const Jackpot = () => {
  const [load, setLoad] = useState(true);

  const [loadingGamesAdd, setLoadingGamesAdd] = useState(false);
  const [loadStop, setLoadStop] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(true);

  const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);
  const [checkedGame, setCheckedGame] = useState(-1);
  const page = useRef(1);

  const [seacrhGames, setSeacrhGames] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);

  useEffect(() => {
    page.current = 1;
    setLoadStop(false);
    async function casinoGames() {
      setLoadingAdd(true);
      const res = await CasinoInfo.games(
        `/jackpotslotlar?current_page=1&limit=25&page=${page.current}&is_mobile=1`
      );
      setCasinoGames(res.data);
      setLoadingAdd(false);
    }
    async function casinoGamesSearch() {
      setLoadingAdd(true);
      setLoadingGamesAdd(true);
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=25&page=${page.current}&search=${seacrhGames}&is_mobile=1`
      );
      setCasinoGames(res.data);
      setLoadingGamesAdd(false);
      if (res.data.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
      setLoadingAdd(false);
    }
    if (seacrhGames.length === 0) {
      casinoGames();
    } else {
      casinoGamesSearch();
    }
  }, [seacrhGames]);

  async function loadMoreItem() {
    async function casinoGames() {
      if (!loadStop) {
        page.current++;
        setLoadingGamesAdd(true);
        const res = await CasinoInfo.games(
          `/jackpotslotlar?current_page=1&limit=25&page=${page.current}&is_mobile=1`
        );
        setCasinoGames((prevState) => prevState.concat(res.data));
        if (res.data.length === 0) {
          setLoadStop(true);
        } else {
          setLoadStop(false);
        }
        setLoadingGamesAdd(false);
        setLoadingAdd(false);
      }
    }
    async function casinoGamesSearch() {
      page.current++;
      setLoadingGamesAdd(true);
      const res = await CasinoInfo.searchGames(
        `?current_page=1&limit=25&page=${page.current}&search=${seacrhGames}&is_mobile=1`
      );
      setCasinoGames((prevState) => prevState.concat(res.data));
      setLoadingGamesAdd(false);
      if (res.data.length === 0) {
        setLoadStop(true);
      } else {
        setLoadStop(false);
      }
      setLoadingGamesAdd(false);
      setLoadingAdd(false);
    }
    if (seacrhGames.length === 0) {
      if (!loadStop) {
        casinoGames();
      }
    } else {
      if (!loadStop) {
        casinoGamesSearch();
      }
    }
  }
  return (
    <Layout
      containerStyles="is-home-page jackpot compact-footer "
      navHeader="jackpot"
      loadStop={loadStop}
      footerNav="kacjpot"
      footerNavType="castino"
    >
      <div className="layout-content-holder-bc">
        <div className="casinoJackpotWrapper-bc FGS ">
          <JackpotTopBar />
          <JackpotLeavelBlock />
          <JackpotLeavleList />
          <JackpotSearch
            seacrhGames={seacrhGames}
            setSeacrhGames={setSeacrhGames}
            />
            <CasinoList
              loadingAdd={loadingAdd}
              casinoGames={casinoGames}
              checkedGame={checkedGame}
              setCheckedGame={setCheckedGame}
              loadMoreItem={loadMoreItem}
              loadingGamesAdd={loadingGamesAdd}
            />
        </div>
      </div>
    </Layout>
  );
};

export { Jackpot };
