import { t } from "i18next";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTypeSelector } from "../../../../../hooks/useTypeSelector";
import { AuthService, AdminPanel } from "../../../../../services";
import { AddressInput } from "./Inputs/AddressInput/AddressInput";
import { AffiliateInput } from "./Inputs/AffiliateInput/AffiliateInput";
import { AgreementInput } from "./Inputs/AgreementInput/AgreementInput";
import { BirthdayInput } from "./Inputs/BirthdayInput/BirthdayInput";
import { CityInput } from "./Inputs/CityInput/CityInput";
import { CountryInput } from "./Inputs/CountryInput/CountryInput";
import { CurrencyInput } from "./Inputs/CurrencyInput/CurrencyInput";
import { EmailInput } from "./Inputs/EmailInput/EmailInput";
import { FirstnameInput } from "./Inputs/FirstnameInput/FirstnameInput";
import { GenderInput } from "./Inputs/GenderInput/GenderInput";
import { LastnameInput } from "./Inputs/LastnameInput/LastnameInput";
import { PassportInput } from "./Inputs/PassportInput/PassportInput";
import { PasswordConfirmInput } from "./Inputs/PasswordConfirmInput/PasswordConfirmInput";
import { PasswordInput } from "./Inputs/PasswordInput/PasswordInput";
import { PhoneNumberInput } from "./Inputs/PhoneNumberInput/PhoneNumberInput";
import { PromocodeInput } from "./Inputs/PromocodeInput/PromocodeInput";
import { UsernameInput } from "./Inputs/UsernameInput/UsernameInput";

import "./RegisterForm.scss";
import {
 setAuth,
 setUserInfo,
} from "../../../../../store/reducers/accountInfoReducer";

export type userFormType = {
 login: string;
 address: string;
 agree: boolean;
 city: string;
 email: string;
 firstname: string;
 lastname: string;
 passport: string;
 password: string;
 password_confirmation: string;
 phone: string;
 username: string;
 gender: number;
 country: string;
 currency: string;
};

interface IRegisterForm {
 handleClosePopap: () => void;
}

const RegisterForm = ({ handleClosePopap }: IRegisterForm) => {
 const defaultBirthday = new Date();
 defaultBirthday.setFullYear(defaultBirthday.getFullYear() - 18);

 const [birthdayData, setBirthdayData] = useState<Date | null>(defaultBirthday);
 const [errorsRequest, setErrorsRequest] = useState<any>();
 const [promocode, setPromocode] = useState("");
 const [phoneCountry, setPhoneCountry] = useState("90");
 const dispatch = useDispatch();
 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 const {
  register,
  handleSubmit,
  watch,
  formState: { errors },
 } = useForm<userFormType>();

 const affid = localStorage.getItem("affid") || null;
 const cxd = localStorage.getItem("cxd") || null;
 const bpext = localStorage.getItem("bpext") || null;

 const birthdayDataString = birthdayData?.toString();
 const onSubmit = (data: userFormType) => {
  async function autotizationLogin() {
   if (birthdayDataString) {
    const dateObj = new Date(birthdayDataString);
    const year = dateObj.getFullYear();
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const desiredFormat = `${year}-${month}-${day}`;
    try {
     const res = await AuthService.register({
      address: data.address,
      affid: affid,
      birthday: desiredFormat,
      country: data.country,
      currency: data.currency,
      cxd: cxd,
      email: data.email,
      firstname: data.firstname,
      gender: data.gender,
      language: language,
      lastname: data.lastname,
      passport: data.passport,
      password: data.password,
      password_confirmation: data.password_confirmation,
      phone: phoneCountry + data.phone,
      promocode: promocode,
      state: "AF2312",
      username: data.username,
      zipcode: "603163",
      bpext:bpext 
     });
     if (res.status === 200) {
      dispatch(setAuth("player"));
      localStorage.setItem("token", res.data.token);
      const data = await AdminPanel.userInfo(res.data.token);
      dispatch(setUserInfo(data.data));
      handleClosePopap();
      setErrorsRequest(null);
      window.location.reload();
     }
    } catch (e: any) {
     // setInvalidData(true);
     setErrorsRequest(e.response.data.errors);
    }
   }
  }
  autotizationLogin();
 };

 return (
  <form
   className="entrance-form-bc registration popup"
   onSubmit={handleSubmit(onSubmit)}
  >
   <div
    className="entrance-form-content-bc single-side step-0"
    data-scroll-lock-scrollable=""
   >
    <div className="reg-form-content" data-scroll-lock-scrollable="">
     <div className="sg-n-text-row-1-bc">{t("admin.Newmember")}?</div>
     <div className="sg-n-text-row-2-bc">{t("admin.Registernow")}!</div>
     <div className="reg-form-fields">
      <div className="entrance-form-content-section-bc">
       <FirstnameInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <LastnameInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <UsernameInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <PasswordInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <PasswordConfirmInput
        register={register}
        watch={watch}
        // errorsRequest={errorsRequest}
       />
       <BirthdayInput
        birthdayData={birthdayData}
        setBirthdayData={setBirthdayData}
        errorsRequest={errorsRequest}
       />
       <PassportInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <EmailInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <GenderInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <CountryInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <CityInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <AddressInput
        register={register}
        watch={watch}
        errorsRequest={errorsRequest}
       />
       <PhoneNumberInput
        register={register}
        watch={watch}
        setPhoneCountry={setPhoneCountry}
        errorsRequest={errorsRequest}
       />
       <CurrencyInput
        register={register}
        // errorsRequest={errorsRequest}
       />
       <AffiliateInput />
       <PromocodeInput promocode={promocode} setPromocode={setPromocode} />
       <AgreementInput register={register} />
      </div>
     </div>
     <div className="entrance-f-item-bc entrance-f-recaptcha-bc">
      <div className="u-i-p-control-item-holder-bc ">
       <img
        className="recaptcha-img"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABICAMAAABiM0N1AAABL1BMVEUAAAD///////////////////////////////////////////////+rq6tChfQcOqkkQa0hP6xUkfatra1OjfX19fU+fezX5v3i5vS1tbU3UrSwsLCmqbCvr6/t7e2xsbH8/P1XkvYuSrGkqLDi4uK4ubn4+v1Bg/Lx8fHW1tbIyMjDw8O9vb20tLSnqq7m7/7m6fZgmfZHiPS5zPPv7+9Cge/M0uzH0Ove3t5bccE+WLezs7MnRK7y9//C2PyryfuZvvpuovdEhPDs7Ozo6Ojk5OSwu+G7yNygrduMm9TM3v2yzvv19viEsPh5qfhZlPZkmfOvxPCqwfCRsvB2n+3f5OzK1+vY3um/z+h7nua6w+Wst+Crtt/Z2dmTodaPntXByNTR0dFsf8hNZLxHYLreSXCvAAAADHRSTlMA/fPy2baba04cGm5J2ZqkAAACaElEQVRYw63YaVfaQBSA4bALjgFssLUkhEZAWVxQAQGX1rp23/e9/v/f4BgTZ5IL3EHv+80RnjPnDJdDRvNKpxLxKJuyaDyRSmtymWSE3bJIMiOcmRi7Q7EZ35lFtoNuatbbD+bgkrunTIzduViGQ0lGUJKfe4QCiqS1FCMppSVooIQWp4HiWpQGimqhhfX2vJyxedjpLDiD//9+fatNlMIQu2/o4Raybrn+9xoKQQlCPOf8BQ5BCUKcOhMv3AAQkCAk6vvvfzDAIPYeQHJO99p5mMegt8sQktvtuk4Wg7Y8B0BC6l05AIIOBmVzP7iDQOuHOg7xUOiJTgMd6DTQnjECMk7O+86U0GN9RI/4P8onF9NAtdCGPhg+xDvLq0PPA0znHZ8WAbGuowLBI1vecudOQKyXV4T2NmXoqzfBAmK/FaF92Xlz813gQ3wucAie2T7zpTZwMOiV5LyWpgY4GNSWoJdiGTgoNC9BB9BRh+RDexZ0NgY50O5YyDTNJXPpKtO0ijzLqhYYGoTW5sItAkcJwhx1CDoEEOIgEOasqEH3AtAfBtqxnqpDos9gP9YckFQg8yMLVBryxWJJHRKdMqlji68ICYfkqhX/1/N2g/8pJAyC2c165Xjnb0teKuGQWvY2BSQkHMJr4dDi6RruDFdQiM/FF1SygQMgd756iFTET82b00/mJGcV/xzdfK8WmuOdZo2hUEGac3vMdirI0ILKdXsEUy8zFIJUpRn4YFkNb/BwCFpH9UarOlyttho/j8psUmQPfmSPomQPx2SP62QXCFRXGmSXLFTXPlQXUVRXY6SXdWTXh5dIMReAck/CkAAAAABJRU5ErkJggg=="
        alt=""
       />
       <div className="form-control-title-static-bc">
        <Link
         to="https://policies.google.com/privacy"
         rel="noopener noreferrer"
         target="_blank"
        >
         {t("admin.PrivacyPolicy")}
        </Link>{" "}
        {t("admin.and")}{" "}
        <Link
         to="https://policies.google.com/terms"
         rel="noopener noreferrer"
         target="_blank"
        >
         {t("admin.TermsService")}
        </Link>{" "}
       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="reg-form-footer-bc">
    <div className="entrance-form-actions-holder-bc reg-ext-1">
     <button className="btn a-color " type="submit" title="Register">
      <span>{t("admin.Register")}</span>
     </button>
    </div>
    <div className="steps-indicator">
     <div className="step-indicator step-indicator-active" />
    </div>
    <div title="Contact support" className="live-chat-adviser-bc">
     <i className="bc-i-live-chat" />
     <span className="ellipsis">{t("admin.Contactsupport")}</span>
    </div>
   </div>
  </form>
 );
};

export { RegisterForm };
