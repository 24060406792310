import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "../../../hooks/useTypeSelector";

import "./FooterNavigation.scss";

interface IFooterNavigation {
  setbetSlipHidden: React.Dispatch<React.SetStateAction<boolean>>;
  setMenuNavigationVisibaly: React.Dispatch<React.SetStateAction<boolean>>;
  footerNav?: string;
}

const FooterNavigation = ({
  setbetSlipHidden,
  setMenuNavigationVisibaly,
  footerNav,
}: IFooterNavigation) => {
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const handleOpenBetslip = () => {
    setbetSlipHidden(true);
  };
  const handleOpenNavigation = () => {
    setMenuNavigationVisibaly(true);
  };
  return (
    <div className="layout-navigation-holder-bc">
      <div className="tab-navigation-w-bc custom-footer-contrainer">
        <Link
          className={`tab-nav-item-bc custom-tab-nav-item-bc ${
            footerNav === "live" ? "active" : ""
          } custom-footer-color`}
          to={`/${language}/sports/live/event-view`}
        >
          <i className="tab-nav-icon-bc bc-i-live" />
          <p className="tab-nav-title-bc custom-tab-nav-title-bc ellipsis">
            {t("admin.Live")}
          </p>
        </Link>
        <Link
            className={`tab-nav-item-bc custom-tab-nav-item-bc ${
              footerNav === "games" ? "active" : ""
            } custom-footer-color`}
            to={`/${language}/games`}
          >
            <i className="tab-nav-icon-bc bc-i-games" />
            <p className="tab-nav-title-bc custom-tab-nav-title-bc ellipsis">
              {t("navHeader.Games")}
            </p>
          </Link>
          <Link
            className={`tab-nav-item-bc custom-tab-nav-item-bc ${
              footerNav === "liveCasino" ? "active" : ""
            } custom-footer-color`}
            to={`/${language}/live-casino/home/-1/All`}
          >
            <i className="tab-nav-icon-bc bc-i-livecasino" />
            <p className="tab-nav-title-bc custom-tab-nav-title-bc ellipsis">
              {t("admin.LiveCasino")}
            </p>
          </Link>

        <Link
          className={`tab-nav-item-bc custom-tab-nav-item-bc ${
            footerNav === "casino" ? "active" : ""
          } custom-footer-color`}
          to={`/${language}/casino/slots/-1`}
        >
          <i className="tab-nav-icon-bc bc-i-slots" />
          <p className="tab-nav-title-bc custom-tab-nav-title-bc ellipsis">
            {t("navHeader.Casino")}
          </p>
        </Link>
        <div
          className="tab-nav-item-bc custom-tab-nav-item-bc menu custom-footer-color"
          onClick={handleOpenNavigation}
        >
          <i className="tab-nav-icon-bc bc-i-burger" />
          <p className="tab-nav-title-bc custom-tab-nav-title-bc ellipsis">
            {t("admin.Menu")}
          </p>
        </div>
      </div>
    </div>
  );
};

export { FooterNavigation };
