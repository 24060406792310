import React from "react";

import "./BetPeriod.scss";
import { useTranslation } from "react-i18next";

const BetPeriod = () => {
  const { t } = useTranslation();
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div className="form-control-bc select has-icon valid filled">
        <label className="form-control-label-bc inputs">
          <select className="form-control-select-bc active" name="period">
            <option value={24}>24 {t("admin.hours")}</option>
            <option value={72}>72 {t("admin.hours")}</option>
            <option value={168}>{t("admin.Birhafta")}</option>
            <option value={720}>30 {t("admin.Days")}</option>
            <option value="">{t("admin.Custom")}</option>
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.Period")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { BetPeriod };
