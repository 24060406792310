import React from "react";

import "./CaruselCart.scss";
import { BannersList } from "../../../common/types/Banners/BannersList";

interface CaruselCart {
  cartCaruselInfo: BannersList;
  handleNavigate: (item: BannersList) => void;
}

const CaruselCart = ({ cartCaruselInfo, handleNavigate }: CaruselCart) => {
  return (
    <div
      className="carousel-slide "
      style={{ width: "100%", left: "0%" }}
      onClick={() => handleNavigate(cartCaruselInfo)}
    >
      <div className="sdr-item-holder-bc">
        <a className="sdr-item-bc">
          <img
            src={cartCaruselInfo.mobile_image.s3}
            loading="lazy"
            className="sdr-image-bc"
            alt=""
            title={cartCaruselInfo.title}
          />
        </a>
      </div>
    </div>
  );
};

export { CaruselCart };
