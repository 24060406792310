import React, { useEffect, useState } from "react";
import { CasinoGamesSlot } from "../casinogamesslot/CasinoGamesSlot";
import { GamesSlot } from "../gamesslot/GamesSlot";
import { LiveCasinoSlot } from "../livecasinoslot/LiveCasinoSlot";
import { CasinoInfo } from "services";
import { CasinoIconGameType } from "srcm/common/types/Casino/CasinoIconGameType";

const CasinoMainWrapper = () => {
 const [casinoGames, setCasinoGames] = useState<CasinoIconGameType[]>([]);

 useEffect(() => {
  async function getCasinoGames() {
   try {
    const res = await CasinoInfo.mainPageGames();
    setCasinoGames(res.data.data);
   } catch (e) {
    console.error(e);
   }
  }
  getCasinoGames();
 }, []);
 return (
  <>
   <CasinoGamesSlot casinoGames={casinoGames} />
   <LiveCasinoSlot casinoGames={casinoGames} />
  </>
 );
};

export { CasinoMainWrapper };
