import axios from "axios";
import { useState, useEffect } from "react";
import { AdminPanel } from "srcm/services";

const useTheme = () => {
  const [themeCssContent, setThemeCssContent] = useState<any>("");
  useEffect(() => {
    document.body.setAttribute("dir", "ltr");
  }, []);
  async function getColors() {
    try {
      const res = await AdminPanel.mainSyles();
      const resStyle = await axios.get(res.data.data.path_to_file);
      setThemeCssContent(
        resStyle.data
          .replace(/.*{/, "")
          .replace(/.*}/, "")
          .replace(/--(.*):\s*(\d{1,3}\s\d{1,3}\s\d{1,3});/g, "--$1: rgb($2);")
      );
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    getColors();
  }, []);
  // useEffect(() => {
  //   // Применяем стили к элементу <html>
  //   const htmlElement = document.querySelector("html");
  //   if (htmlElement) {
  //     htmlElement.setAttribute(
  //       "style",
  //       htmlElement.getAttribute("style") + ";" + themeCssContent
  //     );
  //   }
  // }, [themeCssContent]);
};

export { useTheme };
