import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { useTypeSelector } from "../../../../../hooks/useTypeSelector";
import "./NavHeader.scss";

interface INavHeader {
 navHeader: string;
}

const NavHeader = ({ navHeader }: INavHeader) => {
 const language = useTypeSelector(
  (data) => data.settingsReducer.language.languageShort
 );
 return (
  <div className="hdr-navigation-scrollable-bc-holder" style={{ zIndex: 10 }}>
   <div
    className="hdr-navigation-scrollable-bc scroll-start"
    data-scroll-lock-scrollable=""
   >
    <nav className="hdr-navigation-scrollable-content">
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "sports" ? "active" : ""
      }`}
      to={`/${language}/sports/pre-match/event-view`}
     >
      {t("navHeader.Live")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "sports" ? "active" : ""
      }`}
      to={`/${language}/sports/pre-match/event-view`}
     >
      {t("navHeader.Sport")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "casino" ? "active" : ""
      }`}
      target="_self"
      to={`/${language}/casino/slots/-1`}
     >
      {t("navHeader.Slot")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "livecasino" ? "active" : ""
      }`}
      target="_self"
      to={`/${language}/live-casino/home/-1/All`}
      aria-current="page"
     >
      {t("navHeader.LiveCasino")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "aviator" ? "active" : ""
      }`}
      target="_self"
      to={`/${language}/casino/game-view/806666`}
     >
      {t("navHeader.AVIATOR")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "spaceman" ? "active" : ""
      }`}
      target="_self"
      to={`/${language}/casino/game-view/400043028`}
     >
      {t("navHeader.SPACEMAN")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "jackpot" ? "active" : ""
      }`}
      target="_self"
      to={`/${language}/casino/jackpot`}
     >
      {t("navHeader.Jackpot")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "games" ? "active" : ""
      }`}
      target="_self"
      to={`/${language}/games`}
     >
      {t("navHeader.Games")}
     </Link>
     <Link
      className="hdr-navigation-link-bc  hdr-navigation-link-bc-custom"
      target="_self"
      to={`/${language}/virtual-sports/betconstruct`}
     >
      {t("navHeader.VirtualSports")}
     </Link>
     <Link
      className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
       navHeader === "promotions" ? "active" : ""
      }`}
      target="_self"
      to={`/${language}/promotions/all`}
     >
      {t("navHeader.Promotions")}
     </Link>
    </nav>
   </div>
   {navHeader === "casino" ? (
    <div
     className="hdr-navigation-scrollable-bc"
     data-scroll-lock-scrollable=""
     style={{ touchAction: "pan-x" }}
    >
     <nav className="hdr-navigation-scrollable-content">
      <Link
       aria-current="page"
       className="hdr-navigation-link-bc  hdr-navigation-link-bc-custom active"
       target="_self"
       to={`/${language}/`}
      >
       {t("navHeader.Home")}
      </Link>
     </nav>
    </div>
   ) : null}
   {navHeader === "sports" ||
   navHeader === "liveCalendar" ||
   navHeader === "tournamentsSports" ? (
    <>
     {language === "tr" ? (
      <div
       className="hdr-navigation-scrollable-bc"
       data-scroll-lock-scrollable=""
       style={{ touchAction: "pan-x" }}
      >
       <nav className="hdr-navigation-scrollable-content">
        <Link
         className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
          navHeader === "sports" ? "active" : ""
         }`}
         target="_self"
         to={`/${language}/sports/pre-match/event-view`}
        >
         SPOR BAHİSLERİ
        </Link>
        <Link
         className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
          navHeader === "tournamentsSports" ? "active" : ""
         }`}
         target="_self"
         to={`/${language}/sports/pre-match/tournaments`}
         aria-current="page"
        >
         {t("navHeader.SportTournaments")}
        </Link>
       </nav>
      </div>
     ) : (
      <div
       className="hdr-navigation-scrollable-bc"
       data-scroll-lock-scrollable=""
       style={{ touchAction: "pan-x" }}
      >
       <nav className="hdr-navigation-scrollable-content">
        <Link
         className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
          navHeader === "sports" ? "active" : ""
         }`}
         target="_self"
         to={`/${language}/sports/pre-match/event-view`}
         aria-current="page"
        >
         Event View
        </Link>
        <Link
         className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
          navHeader === "liveCalendar" ? "active" : ""
         }`}
         target="_self"
         to={`/${language}/sports/pre-match/live-calendar`}
        >
         Live Calendar
        </Link>
        <Link
         className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
          navHeader === "sports" ? "active" : ""
         }`}
         target="_self"
         to={`/${language}/sports/pre-match/event-view`}
         aria-current="page"
        >
         SPOR BAHİSLERİ
        </Link>
        <Link
         className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
          navHeader === "tournamentsSports" ? "active" : ""
         }`}
         target="_self"
         to={`/${language}/sports/pre-match/tournaments`}
        >
         {t("navHeader.SportTournaments")}
        </Link>
       </nav>
      </div>
     )}
    </>
   ) : null}
   {navHeader === "betconstruct" ||
   navHeader === "edgegaming" ||
   navHeader === "leapgaming" ||
   navHeader === "kiron" ? (
    <div
     className="hdr-navigation-scrollable-bc"
     data-scroll-lock-scrollable=""
     style={{ touchAction: "pan-x" }}
    >
     <nav className="hdr-navigation-scrollable-content">
      <Link
       className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
        navHeader === "betconstruct" ? "active" : ""
       }`}
       target="_self"
       to={`/${language}/virtual-sports/betconstruct`}
      >
       BetConstruct
      </Link>
      <Link
       className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
        navHeader === "edgegaming" ? "active" : ""
       }`}
       target="_self"
       to={`/${language}/virtual-sports/ext/game/155000`}
       aria-current="page"
      >
       Edge Gaming
      </Link>
      <Link
       className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
        navHeader === "leapgaming" ? "active" : ""
       }`}
       target="_self"
       to={`/${language}/virtual-sports/ext/game/4000235`}
      >
       Leap Gaming
      </Link>
      <Link
       className={`hdr-navigation-link-bc  hdr-navigation-link-bc-custom ${
        navHeader === "kiron" ? "active" : ""
       }`}
       target="_self"
       to={`/${language}/virtual-sports/ext/game/40004001`}
      >
       KIRON
      </Link>
     </nav>
    </div>
   ) : null}
  </div>
 );
};

export { NavHeader };
