import axios from "axios";
const baseURL = process.env.REACT_APP_API_ORIGIN_URL;
const brand = process.env.REACT_APP_API_ORIGIN_BRAND;

const axiosInstanceTokenLess = axios.create({
  baseURL: `${baseURL}/api/v1`,
});

axiosInstanceTokenLess.interceptors.request.use(
  (config) => {
    const code = "en";
    const newConfig = { ...config };
    newConfig.headers.lang = code;
    return newConfig;
  },
  (error) => Promise.reject(error)
);

axiosInstanceTokenLess.defaults.headers.common.brand = brand;

export { axiosInstanceTokenLess };
