import React, { useEffect, useState } from "react";
import { Layout } from "../../components/layout";
import { Carusel } from "../../components/carusel/Carusel";
import { CasinoGamesSlot } from "../../components/main/casinogamesslot/CasinoGamesSlot";
import { LiveCasinoSlot } from "../../components/main/livecasinoslot/LiveCasinoSlot";

import "./Main.scss";
import { useTypeSelector } from "../../hooks/useTypeSelector";
import { BananersEnum } from "../../common/types/Banners/BannersList";
import { BannersBetzula } from "srcm/components/bannersbetzula/BannersBetzula";
import { BannersBetzulaAnimated } from "srcm/components/bannersbetzulaanimated/BannersBetzulaAnimated";
import { CaruselBetzula } from "srcm/components/caruselbetzula/CaruselBetzula";
import { CasinoMainWrapper } from "srcm/components/main/casinomainwrapper/CasinoMainWrapper";

const Main = () => {
  const [load, setLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 1000);
  }, []);
  const bannersList = useTypeSelector(
    (data) => data.accountInfoReducer.banners
  );
  const campaignIds = Comm100API.get("livechat.campaignIds");
  const campaignId = campaignIds[0];
  return (
    <Layout containerStyles="is-home-page " navHeader="">
      <div className="layout-content-holder-bc">
        <Carusel
          mockCarusel={bannersList}
          filtredSelect={BananersEnum.Homepage}
        />
        <CaruselBetzula mockCarusel={bannersList} />
        <BannersBetzula />
        <CasinoMainWrapper />
        <BannersBetzulaAnimated />
      </div>
    </Layout>
  );
};

export { Main };
