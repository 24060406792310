import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
 Link,
 useLocation,
 useNavigate,
 useSearchParams,
} from "react-router-dom";
import "./InboxChat.scss";
import { AdminPanel } from "services";
import {
 messagesFullListResponce,
 messagesFullListResponceMessages,
} from "common/types/AdminInfo/messages/messagesFullListResponce";
import { MessageChat } from "./message/MessageChat";
import { useTypeSelector } from "hooks/useTypeSelector";
import { useDispatch } from "react-redux";
import { setNotification } from "store/reducers/settingsReducer";
import {
 departmentList,
 departmentListById,
} from "common/types/AdminInfo/messages/departmentEnum";

const InboxChat = () => {
 const [messageInfo, setMessageInfo] = useState<messagesFullListResponce>();
 const [messages, setMessages] = useState<messagesFullListResponceMessages[]>();
 const dispatch = useDispatch();
 const { notification } = useTypeSelector((data) => data.settingsReducer);
 const [messageValue, setMessageValue] = useState("");
 const [searchParams] = useSearchParams();
 const navigate = useNavigate();
 const location = useLocation();
 const idPage = searchParams.get("idPage") || "";

 const getMessages = () => {
  AdminPanel.showTicket(idPage)
   .then((res) => {
    setMessages(res.data.messages);
    setMessageInfo(res.data);
   })
   .catch((e) => console.error(e));
 };
 const handleSendMessage = () => {
  AdminPanel.newMessageCreate({
   message: messageValue,
   ticket_id: String(messageInfo?.id),
  })
   .then((res) => {
    setMessageValue("");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setMessages([...messages, res.data]);
   })
   .catch((e) => console.error(e));
 };
 const hangeChangeValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  setMessageValue(e.target.value);
 };
 useEffect(() => {
  getMessages();
 }, [notification]);
 return (
  <div className="chat-inbox-container">
   <div className="message-info-container">
    <div className="messageTitleInfo">
     Subject : {messageInfo?.type === "sent" ? "subject" : ""}
    </div>
    <div className="messageTitleInfo">
     Department :{" "}
     {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      departmentListById[String(messageInfo?.department_id)]
     }
    </div>
    <div
     className="messageTitleInfo"
     style={{ display: "flex", flexDirection: "row" }}
    >
     Status :{" "}
     <div style={{ color: "green", marginLeft: 5 }}>
      {messageInfo?.status_id === 1 ? "sent" : "inbox"}{" "}
     </div>
    </div>
   </div>
   <div className="contnet-container">
    <div className="content-wrapper">
     {messages?.map((item) => {
      return <MessageChat key={item.id} messageInfo={item} />;
     })}
    </div>
   </div>
   <div className="chat-input-container">
    <textarea
     className="chat-input"
     value={messageValue}
     onChange={hangeChangeValue}
    />
    <button className="chat-send-button" onClick={handleSendMessage}>
     Send
    </button>
   </div>
  </div>
 );
};

export { InboxChat };
