import axios from "axios";
const baseURL = process.env.REACT_APP_API_ORIGIN_URL;
const brand = process.env.REACT_APP_API_ORIGIN_BRAND;

const axiosInstance = axios.create({
  baseURL: `${baseURL}/api/v1`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const code = "en";
    const newConfig = { ...config };
    newConfig.headers.lang = code;
    return newConfig;
  },
  (error) => Promise.reject(error)
);

axiosInstance.defaults.headers.common.brand = brand;

const token = localStorage.getItem("token");
if (token) {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export { axiosInstance };
