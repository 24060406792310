import React from "react";

import "./BetType.scss";
import { useTranslation } from "react-i18next";

interface IBetType {
  betType: string;
  setBetType: React.Dispatch<React.SetStateAction<string>>;
  setPaginationPage: React.Dispatch<React.SetStateAction<number>>;
}

const BetType = ({ betType, setBetType, setPaginationPage }: IBetType) => {
  const { t } = useTranslation();
  const handleSelectItem = (
    item: React.MouseEvent<HTMLSelectElement, MouseEvent>
  ) => {
    setBetType(item.currentTarget.value);
    setPaginationPage(1);
  };
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div className="form-control-bc select has-icon valid filled">
        <label className="form-control-label-bc inputs">
          <select
            className="form-control-select-bc active"
            name="bet_type"
            onClick={handleSelectItem}
          >
            <option value="">{t("admin.All")}</option>
            <option value="casino">{t("admin.Casino")}</option>
            <option value="sportbook">{t("admin.Sportbook")}</option>
          </select>
          <i className="form-control-icon-bc bc-i-small-arrow-down" />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">
            {t("admin.BetType")}
          </span>
        </label>
      </div>
    </div>
  );
};

export { BetType };
