import React, { useEffect, useState } from "react";
import { Layout } from "../../components/layout";

import "./SpaceMan.scss";
import { useTypeSelector } from "hooks/useTypeSelector";
import { CasinoInfo } from "services";

const SpaceMan = () => {
  const [gameLink, setGameLink] = useState("");
  const language = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const token = localStorage.getItem("token");
  useEffect(() => {
    async function searchPlaySecond() {
      try {
        if (token) {
          const res = await CasinoInfo.casinoPlay(
            `/pragmaticexternal/pragmaticexternal-spaceman?currency=TRY&lang=${language}&platform=mobile&game_link=true`
          );
          setGameLink(res.data.data.game_link);
        } else {
          const res = await CasinoInfo.casinoPlayDemo(
            `/pragmaticexternal/pragmaticexternal-spaceman?currency=TRY&lang=${language}&platform=mobile&game_link=true`
          );
          setGameLink(res.data.data.game_link);
        }
      } catch {
        console.error("");
      }
    }
    searchPlaySecond();
  }, []);
  return (
    <Layout
      containerStyles="is-home-page compact-footer hide-tab-navigation-footer-mobile "
      navHeader="spaceman"
      loadStop={false}
      footerNav="casino"
    >
      <div className="layout-content-holder-bc custom">
      <iframe
          className="iframe-full-page"
          title={gameLink}
          src={gameLink}
          allowFullScreen={true}
          allow="autoplay; microphone *"
        />
      </div>
    </Layout>
  );
};

export { SpaceMan };
