import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import "./CaruselBetzula.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  BananersEnum,
  BannersList,
} from "srcm/common/types/Banners/BannersList";
import { useTypeSelector } from "hooks/useTypeSelector";

type SwiperInstance = SwiperType | null;

interface ICaruselBetzula {
  mockCarusel?: BannersList[];
  filtredSelect?: BananersEnum;
}

const CaruselBetzula = ({ mockCarusel }: ICaruselBetzula) => {
  const currentIndexRef = useRef(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const lang = useTypeSelector(
    (data) => data.settingsReducer.language.languageShort
  );
  const navigate = useNavigate();
  const bannerList =
    mockCarusel?.filter((item) => item.type === BananersEnum.HomepageShort) ||
    [];

  const next = () => {
    if (currentIndexRef.current < bannerList.length) {
      currentIndexRef.current += 1;
    } else {
      currentIndexRef.current = 1;
    }
    setCurrentIndex(currentIndexRef.current);
  };

  const prev = () => {
    if (currentIndexRef.current > 1) {
      currentIndexRef.current -= 1;
    } else {
      currentIndexRef.current = bannerList.length;
    }
    setCurrentIndex(currentIndexRef.current);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 5000);

    return () => clearInterval(interval);
  }, [bannerList.length]);

  const handleNavigate = (item: BannersList) => {
    if (item.url) {
      navigate(`/${lang}/${item.url}`);
    }
  };

  return (
    <div
      className="hm-row-bc has-slider"
      style={{ gridTemplateColumns: "12fr" }}
    >
      <div className="pb-component-wrapper">
        <div className="slider-bc">
          <div className="carousel">
            <div className="carousel-viewport">
              <div
                className="carousel-container"
                style={{
                  transform: `translate3d(-${currentIndex - 1}00%, 0px, 0px)`,
                }}
              >
                {bannerList.map((item, index) => (
                  <div
                    className="carousel-slide"
                    style={{ width: "100%" }}
                    onClick={() => handleNavigate(item)}
                    key={item.id}
                  >
                    <div className="sdr-item-holder-bc">
                      <Link target="_self" className="sdr-item-bc" to="#">
                        <img
                          src={item?.mobile_image.s3 || ""}
                          loading="lazy"
                          className="sdr-image-bc"
                          alt={item?.mobile_image?.s3 || ""}
                          title="zula özel 250 m"
                        />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="carousel-count-arrow-container with-count">
              <i
                className="carousel-arrow-left bc-i-small-arrow-left"
                onClick={prev}
              />
              <div className="carousel-count-arrow">
                {currentIndex} / {bannerList.length || 4}
              </div>
              <i
                className="carousel-arrow-right bc-i-small-arrow-right"
                onClick={next}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CaruselBetzula };
