import React from "react";

import "./FooterLink.scss";
import { Link } from "react-router-dom";

const FooterLink = () => {
  return (
    <div className="footerLinksSection">
      <div className="footerLinkCols">
        <div className="footerLinkCol">
          <h3 className="footerLinkColTitle">BİZE ULAŞIN</h3>
          <ul className="footerLinkColLinks">
            <li className="footerLinkColEl">
              <Link
                target="_self"
                className=""
                to={{
                  search: "?helpPageContent=12401",
                }}
              >
                <i />
                BİZE ULAŞIN
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerLinkCol">
          <h3 className="footerLinkColTitle">HAKKIMIZDA</h3>
          <ul className="footerLinkColLinks">
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12702",
                }}>
                <i />
                ADALET VE RNG TEST YÖNTEMLERİ
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12703",
                }}>
                <i />
                AML KYC POLİTİKALARI
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12704",
                }}>
                <i />
                FİRMA BİLGİLERİ
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=1275",
                }}>
                <i />
                GİZLİLİK POLİTİKASI
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12723",
                }}>
                <i />
                HESAPLAR ÖDEMELER VE BONUSLAR
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12706",
                }}>
                <i />
                KARA PARA AKLANMANIN ÖNLENMESİ
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12707",
                }}>
                <i />
                KENDİNİ DIŞLAMA
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12708",
                }}>
                <i />
                SORUMLU OYUN
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12709",
                }}>
                <i />
                TARTIŞMALI KARAR
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerLinkCol">
          <h3 className="footerLinkColTitle">KURALLAR VE ŞARTLAR</h3>
          <ul className="footerLinkColLinks">
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12710",
                }}>
                <i className="bc-i-live" /> 
                BAHİSLERİN KABULU
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12711",
                }}>
                <i className="bc-i-live" /> 
                GENEL BONUS KURALLARI VE ŞARTLARI
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12712",
                }}>
                <i className="bc-i-live" /> 
                GENEL KURAL VE ŞARTLAR
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12713",
                }}>
                <i className="bc-i-live" /> 
                SPOR BAHİS KURALLARI
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerLinkCol">
          <h3 className="footerLinkColTitle">RİSK KURALLARI</h3>
          <ul className="footerLinkColLinks">
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12714",
                }}>
                <i />
                RİSK BİRİMİ
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerLinkCol">
          <h3 className="footerLinkColTitle">YARDIM</h3>
          <ul className="footerLinkColLinks">
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12715",
                }}>
                <i />
                SPOR BAHİSLERİ
              </Link>
            </li>
            <li className="footerLinkColEl">
              <Link target="_self" className="" to={{
                  search: "?helpPageContent=12716",
                }}>
                <i />
                SSS
              </Link>
            </li>
          </ul>
        </div>
        <div className="footerLinkCol">
         <h3 className="footerLinkColTitle">SOSYAL MEDYA</h3>
         <ul className="footerLinkColLinks">
          <li className="footerLinkColEl">
           <Link
            to="https://www.instagram.com/betkornertr/"
            target="_blank"
            className=""
            rel="noreferrer"
           >
            <i className="bc-i-instagram" /> İNSTAGRAM
           </Link>
          </li>
          <li className="footerLinkColEl">
           <Link
           to="https://x.com/Betkornerresmi"
            target="_blank"
            className=""
            rel="noreferrer"
           >
            <i className="bc-i-draw" /> TWİTTER
           </Link>
          </li>
          <li className="footerLinkColEl">
           <Link
            to="https://www.youtube.com/@BetkornerResmi"
            target="_blank"
            className=""
            rel="noreferrer"
           >
            <i className="bc-i-youtube" /> YOUTUBE
           </Link>
          </li>
          <li className="footerLinkColEl">
           <Link
            to="https://t.me/betkornertr"
            target="_blank"
            className=""
            rel="noreferrer"
           >
            <i className="bc-i-telegram" /> TELEGRAM
           </Link>
          </li>
         </ul>
        </div>
      </div>
    </div>
  );
};

export { FooterLink };
