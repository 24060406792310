import { t } from "i18next";
import React from "react";
import { MessageInput } from "./inpunts/MessageInput/MessageInput";
import { SubjectInput } from "./inpunts/SubjectInput/SubjectInput";

import "./NewPage.scss";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { AdminPanel } from "services";
import { DepratmentInput } from "./inpunts/DepratmentInput/DepratmentInput";

const NewPage = () => {
 const [searchParams] = useSearchParams();
 const navigate = useNavigate();
 const location = useLocation();
 const idPage = searchParams.get("idPage") || "";

 const {
  register,
  handleSubmit,
  formState: { errors },
  watch,
 } = useForm({
  defaultValues: {
   department_id: 1,
   title: "",
   message: "",
  },
 });

 const testMessageCreate = (data: any) => {
  AdminPanel.newTicketCreate(data)
   .then((res) => console.log(res.data))
   .catch((e) => console.error(e));
 };
 const onSubmit = (data: any) => {
  try {
   testMessageCreate(data);
  } catch (e) {
   console.error(e);
  } finally {
   setTimeout(() => {
    searchParams.set("page", "sent");
    navigate(`${location.pathname}?${searchParams.toString()}`);
   }, 1000);
  }
 };
 function isEmpty(obj: any) {
  console.log(obj, Object.keys(obj).length === 0);
  return Object.keys(obj).length !== 0;
 }
 return (
  <div
   className="u-i-e-p-p-content-bc u-i-common-content message-content"
   data-scroll-lock-scrollable=""
  >
   <form onSubmit={handleSubmit(onSubmit)}>
    <DepratmentInput watch={watch} register={register} />
    <SubjectInput watch={watch} register={register} />
    <MessageInput watch={watch} register={register} />
    <div className="u-i-p-c-footer-bc">
     <button
      className="btn a-color "
      type="submit"
      title="Submit"
      disabled={isEmpty(errors)}
     >
      <span>{t("admin.Submit")}</span>
     </button>
    </div>
   </form>
  </div>
 );
};

export { NewPage };
