import { api, apiTokenLess, apiUnauth } from "../http/index";
import { AxiosResponse } from "axios";
import { CasinoGameType } from "../common/types/Casino/CasinoGameType";
import { GameProviderRequest } from "../common/types/Casino/GameProvider";
import { login } from "../common/types/Auth/login";
import { UserInformation } from "../common/types/AdminInfo/UserInformation";
import { userDeposit } from "../common/types/AdminInfo/userDeposit";
import { userPay, userPayBody } from "../common/types/AdminInfo/userPay";
import { userBetsType } from "../common/types/AdminInfo/userBets";
import { register } from "../common/types/Auth/register";
import { changePassword } from "../common/types/Auth/changePassword";
import { CategoriesTypeRequest } from "../common/types/Casino/CategoriesType";
import { FavoritCasinoResponceType } from "../common/types/Casino/FavoritCasinoResponceType";
import { BannersList } from "../common/types/Banners/BannersList";
import { userBalanceType } from "../common/types/AdminInfo/balanceType/userBalanceType";
import { promotionsResponceType } from "../common/types/Promotions/promotionsResponceType";
import { ContentType } from "../common/types/Content/ContentType";
import { PageType } from "../common/types/PageType/PageType";
import {
  axiosInstance,
  axiosInstanceTokenLess,
  axiosInstanceUnauth,
} from "httpApi";
import { transactionsType } from "srcm/common/types/AdminInfo/transactionsType/transactionsType";

class AuthService {
  static async login({
    username,
    password,
  }: login): Promise<AxiosResponse<{ message: string; token: string }>> {
    return axiosInstanceUnauth.post<{ message: string; token: string }>(
      "/login",
      {
        username,
        password,
      }
    );
  }
  static async register({
    address,
    affid,
    birthday,
    country,
    currency,
    cxd,
    email,
    firstname,
    gender,
    language,
    lastname,
    passport,
    password,
    password_confirmation,
    phone,
    promocode,
    state,
    username,
    zipcode,
    bpext,
  }: register): Promise<AxiosResponse<{ message: string; token: string }>> {
    return axiosInstanceUnauth.post<{ message: string; token: string }>(
      "/register",
      {
        address,
        affid,
        birthday,
        country,
        currency,
        cxd,
        email,
        firstname,
        gender,
        language,
        lastname,
        passport,
        password,
        password_confirmation,
        phone,
        promocode,
        state,
        username,
        zipcode,
        bpext,
      }
    );
  }
  static async changePassword({
    current_password,
    password,
    password_confirmation,
  }: changePassword): Promise<AxiosResponse<string>> {
    return axiosInstance.post<string>("/change-password", {
      current_password,
      password,
      password_confirmation,
    });
  }
}

class CasinoInfo {
  static async setFavoriteGame(data: {
    game_id: number;
    type: number;
  }): Promise<{ data: FavoritCasinoResponceType }> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;

    return axiosInstance
      .post<{ data: FavoritCasinoResponceType }>(`/add-to-favorite`, data, {
        headers,
      })
      .then((response) => ({ data: response.data.data }));
  }
  static async providers(
    searchParams?: string
  ): Promise<{ data: GameProviderRequest }> {
    const bearerToken = localStorage.getItem("token");
    const headers = bearerToken
      ? { Authorization: `Bearer ${bearerToken}` }
      : undefined;

    return axiosInstance
      .get<{ data: GameProviderRequest }>(`/providers${searchParams}`, {
        headers,
      })
      .then((response) => ({ data: response.data.data }));
  }

  static async categories(
    searchParams: string
  ): Promise<{ data: CategoriesTypeRequest }> {
    return axiosInstance
      .get<{ data: CategoriesTypeRequest }>(
        `/categories-with-games${searchParams}`
      )
      .then((response) => ({ data: response.data.data }));
  }

  static async games(searchParams: string): Promise<{ data: any }> {
    return axiosInstance
      .get<{ data: any }>(`/categories-with-games${searchParams}`)
      .then((response) => ({ data: response.data.data }));
  }

  static async mainPageGames(): Promise<AxiosResponse<any>> {
    return axiosInstanceTokenLess.get<any>(`/home-page-games-flat?is_all=1`);
  }

  static async searchGames(searchParams: string): Promise<{ data: any }> {
    return axiosInstance
      .get<{ data: any }>(`/search-games${searchParams}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async casinoPlay(
    searchParams: string
  ): Promise<{ data: CasinoGameType }> {
    return axiosInstance
      .get<{ data: CasinoGameType }>(`/casino${searchParams}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async casinoPlayDemo(
    searchParams: string
  ): Promise<{ data: CasinoGameType }> {
    return axiosInstanceTokenLess
      .get<{ data: CasinoGameType }>(`/casino${searchParams}`)
      .then((response) => ({ data: response.data.data }));
  }

  static async casinoPlayFreespins(
    searchParams: string
  ): Promise<{ data: string }> {
    return axiosInstance
      .get<{ data: string }>(
        `/user-dashboard/freespin/request?launch_code=${searchParams}&platform=mobile`
      )
      .then((response) => ({ data: response.data.data }));
  }
  static async favoriteGames(): Promise<{ data: FavoritCasinoResponceType[] }> {
    return axiosInstance
      .get<{ data: FavoritCasinoResponceType[] }>(`/favorites-games`)
      .then((response) => ({ data: response.data.data }));
  }
  static async MainBanners(lang: string): Promise<{ data: BannersList[] }> {
    return axiosInstance
      .get<{ data: BannersList[] }>(`/banners?lang=${lang}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async sportbookStartSession(lang: string): Promise<{ data: string }> {
    return axiosInstance
      .get<{ data: string }>(`/sportbook-start-session?lang=${lang}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async promotions(
    promoId: string
  ): Promise<{ data: promotionsResponceType }> {
    return axiosInstance
      .get<{ data: promotionsResponceType }>(`/promotions/${promoId}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async content(lang: string): Promise<AxiosResponse<ContentType>> {
    return axiosInstance.get<any>(`/content?${lang}`);
  }
  static async page(id: string): Promise<{ data: PageType }> {
    return axiosInstance
      .get<{ data: PageType }>(`/page/${id}`)
      .then((response) => ({ data: response.data.data }));
  }
}

class AdminPanel {
  static async userInfo(token?: string): Promise<{ data: UserInformation }> {
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    return axiosInstance
      .get<{ data: UserInformation }>("/user", { headers })
      .then((response) => ({ data: response.data.data }));
  }

  static async userBalance(): Promise<{ data: userBalanceType }> {
    return axiosInstance
      .get<{ data: userBalanceType }>("/update-user-balance")
      .then((response) => ({ data: response.data.data }));
  }
  static async userBets(params: string): Promise<AxiosResponse<userBetsType>> {
    return axiosInstance.get<userBetsType>(`/user-dashboard/bets${params}`);
  }
  static async userDeposit(params: string): Promise<{ data: userDeposit[] }> {
    return axiosInstance
      .get<{ data: userDeposit[] }>(`/payment-methods${params}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async userPay({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<userPayBody>> {
    return axiosInstance.post<userPayBody>(
      `/user-dashboard/deposits/set${params}`,
      data
    );
  }
  static async userPayManual({
    data,
    params,
  }: {
    data: userPay;
    params: string;
  }): Promise<AxiosResponse<any>> {
    return axiosInstance.post<any>(
      `/user-dashboard/deposits/set-manual${params}`,
      data
    );
  }
  static async userWithdraw({
    data,
  }: {
    data: {
      amount: number;
      currency: string;
      extra_data: {
        accountNumber: string | null;
        bankCode: string | null;
        description: string | null;
        iban: string | null;
        id: string | null;
        nameSurname: string | null;
        notes: string | null;
        tcNum: string | null;
        wallet_id: string | null;
      };
      service_id: number;
    };
  }): Promise<{ data: userPayBody }> {
    return axiosInstance
      .post<{ data: userPayBody }>(`/user-dashboard/withdrawals/set`, data)
      .then((response) => ({ data: response.data.data }));
  }
  static async deleteBonus({
    data,
  }: {
    data: number;
  }): Promise<{ data: string }> {
    return axiosInstance
      .post<{ data: string }>(`/user-dashboard/delete-bonus/${data}`)
      .then((response) => ({ data: response.data.data }));
  }
  static async changePassword({
    current_password,
    password,
    password_confirmation,
  }: changePassword): Promise<{ data: userPayBody }> {
    return axiosInstance
      .post<{ data: userPayBody }>(`/user-dashboard/set-deposit`, {
        current_password,
        password,
        password_confirmation,
      })
      .then((response) => ({ data: response.data.data }));
  }
  static async mainSyles(): Promise<AxiosResponse<any>> {
    return axiosInstance.get<any>(`theme`);
  }
  static async transactions(
    params: string
  ): Promise<{ data: transactionsType[] }> {
    return axiosInstance
      .get<{ data: transactionsType[] }>(
        `/user-dashboard/transactions${params}`
      )
      .then((response) => ({ data: response.data.data }));
  }
}

export { AuthService, CasinoInfo, AdminPanel };
