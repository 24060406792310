const departmentListById = {
 "1": "Unknow   ",
 "2": "Account",
 "3": "SportBetting",
 "4": "Casino",
 "5": "LiveCasino",
 "6": "Bingo",
 "7": "Poker",
 "8": "Other",
};
type DepartmentType = {
 id: string;
 title: string;
};

type departmentListType = Record<string, DepartmentType>;

const departmentList: departmentListType = {
 Account: { id: "2", title: "Account" },
 SportBetting: { id: "3", title: "SportBetting" },
 Casino: { id: "4", title: "Casino" },
 LiveCasino: { id: "5", title: "LiveCasino" },
 Bingo: { id: "6", title: "Bingo" },
 Poker: { id: "7", title: "Poker" },
 Other: { id: "8", title: "Other" },
};

export { departmentList, departmentListById };
export type { departmentListType };
