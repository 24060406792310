import React, { Suspense, lazy, useEffect, useState } from "react";
import { AdminPanel } from "services";
import { useTheme } from "srcm/hooks/useTheme";
import { WebsiteUnavailable } from "srcm/pages";
const AppDesktop = lazy(() => import("./AppDesktop"));
const AppMobile = lazy(() => import("./srcm/AppMobile"));

enum loadingStatus {
 loading = "loading",
 unworking = "unworking",
 working = "working",
}

function App() {
 const [status, setStatus] = useState(loadingStatus.loading);
 async function setSettings() {
  const locationLink = window.location.origin;
  try {
   const data = await AdminPanel.setCheckDomain({
    url: locationLink,
   });
   if (data.data.status) {
    setStatus(loadingStatus.unworking);
   } else {
    setStatus(loadingStatus.working);
   }
  } catch (e) {
   console.error(e);
  }
 }
 useTheme();
 const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
   navigator.userAgent
  );
 useEffect(() => {
  setSettings();
 }, []);
 return (
  <>
   {status === loadingStatus.loading && <div></div>}
   {status === loadingStatus.unworking && <WebsiteUnavailable />}
   {status === loadingStatus.working && (
    <Suspense fallback={<div></div>}>
     {isMobile ? <AppMobile /> : <AppDesktop />}
    </Suspense>
   )}
  </>
 );
}

export default App;
