import React from "react";

import "./WebsiteUnavailable.scss";

const WebsiteUnavailable = () => {
 const goToPreviousPage = () => {
  window.location.reload();
 };
 return (
  <div className="container">
   <h1 className="title">Website Unavailable</h1>
   <p className="message">The website you are trying to reach is unavailable</p>
   <button className="button" onClick={goToPreviousPage}>
    Go to previous page
   </button>
  </div>
 );
};

export { WebsiteUnavailable };
